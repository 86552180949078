import { Container, Icon, Paper, TablePagination } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable, { Action } from 'material-table';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../common/pageHeader';
import { history } from '../_helpers/history';
import { competitionHorseScoringService } from '../_services/competitionHorseScoring.service';
import { format } from 'date-fns';
import { accountService } from '../_services';
import { AccountEdit } from '../account/account-edit';
import { Route } from 'react-router-dom';
import { HorseManage } from './horse-manage';
import { toastr } from '../_components/toastr';

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    gold: {
        color: "#FFD700"
    },
    silver: {
        color: "silver"
    },
    bronze: {
        color: "#cd7f32"
    }
}));



export function HorsesIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });

    const classes = useStyles();

    const columns = [
        { title: 'Position', field: 'position', render: (rowData: any) => rowData.position < 4 ? <><Icon className={(rowData.position == 1) ? classes.gold : (rowData.position == 2) ? classes.silver : classes.bronze}>emoji_events</Icon></> : <>{rowData.position}{rowData.positionString} </> },
        { title: 'Name', field: 'horseName', render: (rowData: any) => <div>{(rowData.horseName)}<br></br>({(rowData.parents)})</div> },
        { title: 'Total Points', field: 'totalPoints' },
        { title: 'Sex', field: 'genderName' },
        { title: 'Category', field: 'categoryName' },
        { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth) ? format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy') : '-'}</> },
    ];

    //accountService.isAdmin

    useEffect(() => { fetchData() }, []);

    function fetchData() {
        setLoading(true);
        return competitionHorseScoringService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function goToHorse(horseId: string) {
        history.push("/Horse/" + horseId);
    }

    function goToEditHorse(horseId: any) {
        history.push("/Horses/Edit/" + horseId);
    }


    function updateHorsePostback(): void {
        toastr.success("Horse updated successfully");
        fetchData();
    }

    return (
        <>
            <Container component="main">


                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Horses' icon='horse'></PageHeader>
                    </div>

                    <MaterialTable
                        onRowClick={(e: any, data: any) => goToHorse(data.horseId)}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        actions={accountService.isAdmin ? [
                            rowData => ({
                                icon: 'edit',
                                tooltip: 'edit',
                                onClick: (e: any) => { goToEditHorse(rowData.horseId) },
                            })
                        ] : []}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {accountService.isAdmin &&
                                            <TableCell >
                                                Action
                                        </TableCell>
                                        }
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false
                        }}


                    />
                </Paper>
            </Container>

            <Route path="/Horses/Edit/:horseId" component={() => <HorseManage successCallback={updateHorsePostback}></HorseManage>} />
        </>
    );
}