import { Icon } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { ADMIN_NAV, PRIMARY_NAV, USER_NAV } from '../_consts/navigationConsts';
import { history } from '../_helpers/history';
import coolmoreLogo from '../_images/Coolmore_dark.png';
import horse_icon_black from '../_images/horse_icon_black.png';
import { accountService } from '../_services/accounts.service';



const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },

        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        logo: {
            padding: "16px",
            color: theme.palette.primary.main
        },
        coolmoreLogo: {
            width: "95%",
            marginTop: theme.spacing(1),
        },
        horseIcon: {
            opacity: "0.64",
            width: "20px"
        }
    }),
);

export default function PermanentDrawerLeft() {
    const classes = useStyles();

    function goTo(link: string) {
        history.push('/' + link);
    }

    return (
        <div>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar} >
                    <img className={classes.coolmoreLogo} src={coolmoreLogo}></img>
                </div>
                <Divider />

                {(PRIMARY_NAV.length > 0) &&
                    <List>
                        {PRIMARY_NAV.map(nav => {
                            return accountService.isAdmin && nav.name === 'Rules' ? <></> :
                                <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                                    {nav.png == null &&
                                        <ListItemIcon>
                                            <Icon>{nav.icon}</Icon>
                                        </ListItemIcon>
                                    }

                                    {nav.png != null &&
                                        <ListItemIcon><><img className={classes.horseIcon} src={horse_icon_black}></img></></ListItemIcon>
                                    }
                                    < ListItemText primary={nav.name} />
                                </ListItem>
                            }
                        )}
                        <Divider />
                    </List>
                }

                {(ADMIN_NAV.length > 0 && (accountService.isAdmin)) &&
                    <List>
                        {ADMIN_NAV.map(nav =>
                            <ListItem button key={nav.name} onClick={e => goTo(nav.url)}>
                                <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                                <ListItemText primary={nav.name} />
                            </ListItem>
                        )}
                        <Divider />
                    </List>
                }

                {(USER_NAV.length > 0) &&
                    <List>
                        {USER_NAV.map(nav =>
                            <ListItem button key={nav.name} onClick={nav.onClick}>
                                <ListItemIcon><Icon>{nav.icon}</Icon></ListItemIcon>
                                <ListItemText primary={nav.name} />
                            </ListItem>
                        )}
                        <Divider />
                    </List>
                }
            </Drawer>
        </div >
    );
}