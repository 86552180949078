import axios from 'axios';
import { API_URL } from '../_config/const';
import { getHeader, getQuery, getUploadHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "FileUpload";

export const uploadsService = {
    getAll,
    post,
    get,
    remove
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetFileUpload/?id=${id}`, requestOptions).then(handleResponse);
}

function post(model: FormData) {
    /*
    console.log("model", model);
    const requestOptions = { method: 'POST', headers: getUploadHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
    */

    const config = {
        headers: getUploadHeader()
    }

    return axios.post(`${API_URL}/${API_NAME}/`, model, config);

}


//function put(id, model) {
//    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
//    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
//}

function remove(id:any) {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/DeleteFileUpload/?id=${id}`, requestOptions).then(handleResponse);

}
