import { Chip, Container, FormControlLabel, FormGroup, Grid, Icon, makeStyles, Paper, Switch, TablePagination, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { competitionUserService } from '../_services/competitionUser.service';
import { horsesService } from '../_services/horse.service';
import { competitionService } from '../_services/competition.service';



interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
    category?: 'two' | 'threeUp';
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    button: {
        margin: theme.spacing(1),
        flex: 1,
        width: "300px"
    },
    page: {
        marginBottom: "50px"
    },
    main: {
        paddingBottom: "100px"
    },
    chip: {
        margin: theme.spacing(1),
    }
}));

function getSteps(splitNo2yold: number, splitNoOlder: number) {
    return ['Select ' + splitNo2yold + ' Two Year Olds', 'Select ' + splitNoOlder + ' Three Year Olds and Older', 'Confirm'];
}


export function CompetitionJoin() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [entryFee, setEntryFee] = useState(0);
    const [splitNo2yold, setSplitNo2yold] = useState(0);
    const [splitNoOlder, setSplitNoOlder] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '', category:"two" });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isCovidPaymentAccepted, setIsCovidPaymentAccepted] = useState(false);

    const { competitionId } = useParams<{ competitionId: string }>();


    const options: any = [
        {
            name: 'Two Year Olds', step: 'Select Two Year Olds', title: `Please Select ${splitNo2yold||'[loading...]'} Two Year Old Horses`, itemsTitle: 'Selected Two Year Olds', category: 'two', selectedItems: []
        },
        {
            name: 'Three Year Olds and Older', step: 'Select Three Year Olds and Older', title: `Please Select ${splitNoOlder || '[loading...]'}  Three Year Old and Older Horses`, itemsTitle: 'Selected Three Year Olds and Older', category: 'threeUp', selectedItems: [] }
    ];


    const [selectedHorses, setSelectedHorses] = useState<any>([[], []]);

    function fetchData() {
        var _filter = filter;
        _filter.category = options[activeStep].category;

        return horsesService.getByCategory(_filter)
            .then(json => {
                setData(json);
                getOngoingCompetition();
            })
            .finally();
    }

    function getOngoingCompetition() {
        return competitionService.getAllOngoingCompetitions()
        .then(json => {
            setEntryFee(json.items[0].entryFee);
            setSplitNo2yold(json.items[0].splitNo2yold);
            setSplitNoOlder(json.items[0].splitNoOlder);
            setLoading(false);
        })
        .finally();
    }
    
    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    const columns = [
        [
            { title: 'Name', field: 'name' },
            { title: 'Category', field: 'category' },
            { title: 'Sex', field: 'sex' },
            { title: 'Location', field: 'location' },
            { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth) ? format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy') : '-'}</> },
        ],
        [
            { title: 'Name', field: 'name' },
            { title: 'Category', field: 'category' },
            { title: 'Sire', field: 'sire' },
            { title: 'Dam', field: 'dam' },
            { title: 'Sex', field: 'sex' },
            { title: 'Location', field: 'location' },
            { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth) ? format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy') : '-'}</> },
        ]
    ];

    const steps = getSteps(splitNo2yold, splitNoOlder);

    useEffect(() => {
        if (activeStep < 2) fetchData();
    }, [activeStep]);


    const classes = useStyles();


    async function handleNext() {
        setActiveStep(prevStep => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSubmit = () => {

        if (isLoading) return;

        var competitionHorses = [...selectedHorses[0], ...selectedHorses[1]];

        if (selectedHorses[0].length !== splitNo2yold || selectedHorses[1].length !== splitNoOlder) {
            toastr.error("Invalid selection. Please review your selection to comply with rules.");
            return false;
        }

        var _item = {
            competitionId: competitionId,
            HorseIds: competitionHorses.map(h => h.id)
        };

        setLoading(true);

        competitionUserService.post(_item).then(
            (data) => {
                toastr.success("Successfully joined the competition");
                history.push("/");
            },
            (e) => {
                toastr.error("ERROR: " + e + " :Something went wrong. Please try again later");
                console.log(e)
            }).finally(() => { setLoading(false) });
    }
    function removeHorse(chip: any) {

        var _allSelectedHorses = selectedHorses;
        _allSelectedHorses[activeStep] = _allSelectedHorses[activeStep].filter((item: any) => item.id !== chip.id);

        setSelectedHorses([..._allSelectedHorses]);
    }

    function addHorse(e: any, data: any) {
        if (isAdded(data) || isFull()) return;

        var _selectedHorses = selectedHorses;
        _selectedHorses[activeStep].push(data);

        setSelectedHorses([..._selectedHorses]);
    }

    function isAdded(data: any) {
        return selectedHorses[activeStep].some((item: any) => item.id === data.id);
    }

    function isFull() {

        if (activeStep === 0) {
            return selectedHorses[activeStep].length >= splitNo2yold;
        }
        else {
            return selectedHorses[activeStep].length >= splitNoOlder;
        }

        
    }

    function getStepHorseAmount() {

        if (activeStep === 0) {
            return splitNo2yold;
        }
        else {
            return splitNoOlder;
        }


    }

    const orientation: "horizontal" | "vertical" = window.innerWidth < 760 ? "vertical" : "horizontal";

    return (
        <>
            <Container component="main" className={classes.main}>
                <Paper className={classes.page}>
                    <Stepper activeStep={activeStep} orientation={orientation}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: { optional?: React.ReactNode } = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <Grid container spacing={0}>

                        {(activeStep > 0) &&
                            <Grid item xs={4}>
                                <Button variant="contained" type="button" fullWidth color="secondary" onClick={handleBack}>
                                    Back
                                    </Button>
                            </Grid>
                        }

                        {(activeStep < 2) &&
                            <Grid item xs={activeStep == 0 ? 12 : 8}>
                                <Button variant="contained" type="button" fullWidth color="primary" onClick={handleNext} disabled={!isFull()}>
                                    Continue
                                    </Button>
                            </Grid>
                        }

                        {(activeStep == 2) &&
                            <Grid item xs={8}>
                                <Button variant="contained" type="button" fullWidth color="primary" onClick={(e) => setIsDialogOpen(true)}>
                                    Confirm and Save
                                </Button>
                            </Grid>
                        }
                    </Grid>

                    {(activeStep == 2) &&
                        <>
                            {options && options.map((option: any, optionIndex: number) =>
                                <>
                                    {(optionIndex < 2) &&
                                        <Paper className={classes.page}>
                                            <Typography variant="h6">{options[optionIndex].itemsTitle}</Typography>
                                            <hr />

                                            {selectedHorses[optionIndex] && selectedHorses[optionIndex].map((item: any) =>
                                                <>
                                                    {(activeStep < 2) &&
                                                        <Chip key={item.id}
                                                            className={classes.chip}
                                                            onDelete={(e) => removeHorse(item)}
                                                            label={item.name}
                                                            icon={<Icon>check</Icon>} />
                                                    }

                                                    {(activeStep == 2) &&
                                                        <Chip key={item.id}
                                                            className={classes.chip}
                                                            label={item.name}
                                                            icon={<Icon>check</Icon>} />
                                                    }
                                                </>
                                            )}

                                            {!selectedHorses[optionIndex].length && <p>No items selected</p>}
                                        </Paper>
                                    }
                                </>
                            )}
                        </>
                    }

                </Paper>
                {(activeStep < 2) &&
                    <>
                        <Paper className={classes.page}>
                        <Typography variant="h6">{options[activeStep].itemsTitle}  [{selectedHorses[activeStep].length}/{getStepHorseAmount()}]</Typography>
                            <hr />
                            {selectedHorses[activeStep] && selectedHorses[activeStep].map((item: any) =>
                                <Chip key={item.id} className={classes.chip} onDelete={(e) => removeHorse(item)} label={item.name} icon={<Icon>check</Icon>} />
                            )}
                            {!selectedHorses[activeStep].length && <p>No items selected</p>}
                        </Paper>

                        <Paper>
                            <CssBaseline />

                            <div className={classes.pageHeader}>
                                <PageHeader title={options[activeStep].title} icon='horse'></PageHeader>
                            </div>

                            <MaterialTable

                                columns={columns[activeStep]}
                                data={data.items}
                                actions={[
                                    rowData => ({
                                        icon: isAdded(rowData) ? 'check' : isFull() ? '' : 'add',
                                        tooltip: 'Add Horse',
                                        onClick: addHorse,
                                        disabled: true
                                    })
                                ]}
                                isLoading={isLoading}
                                onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                                onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                                components={{
                                    Pagination: (componentProps) => <TablePagination
                                        {...componentProps}
                                        count={data.count}
                                        onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}

                                        page={filter.page}
                                        rowsPerPage={filter.limit}
                                    />,
                                    Header: d =>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell >
                                                    Action
                                        </TableCell>
                                                {columns[activeStep] && columns[activeStep].map(h =>
                                                    <TableCell key={h.title}
                                                        sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                        <Tooltip
                                                            title="Sort"
                                                            enterDelay={300}>
                                                            <TableSortLabel
                                                                active={filter.orderBy === h.field}
                                                                direction={filter.order}
                                                                onClick={e => handleRequestSort(h.field)}>
                                                                {h.title}
                                                            </TableSortLabel>
                                                        </Tooltip>
                                                    </TableCell>
                                                )}
                                            </TableRow>

                                        </TableHead>
                                }}
                                options={{
                                    search: true,
                                    toolbar: true,
                                    pageSize: filter.limit,
                                    pageSizeOptions: [5, 10, 20, 50, 100],
                                    initialPage: 0,
                                    showEmptyDataSourceMessage: true,
                                    sorting: true,
                                    showTitle: false,
                                    loadingType: 'linear',
                                    debounceInterval: 200,
                                    emptyRowsWhenPaging: false
                                }}


                            />
                        </Paper>
                    </>
                }


                <div>
                    <Dialog
                        open={isDialogOpen}
                        onClose={(e) => setIsDialogOpen(false)}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Confirmation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>

                                <strong>Payments for entering this competition will be directly deducted from a future payslip (date to be confirmed).</strong>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch
                                            checked={isCovidPaymentAccepted}
                                            onChange={(e) => setIsCovidPaymentAccepted(!isCovidPaymentAccepted)}
                                            color="primary"
                                            name="isCovidPaymentAccepted"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label={`I consent that a single €${entryFee} payment can be deducted from my payslip`}
                                    />
                                </FormGroup>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={(e) => setIsDialogOpen(false)} color="secondary">
                                Cancel
                            </Button>

                            <Button disabled={isLoading || isCovidPaymentAccepted === false} variant="contained" type="button" onClick={handleSubmit} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>


            </Container>
        </>
    );
}