import { API_URL } from '../_config/const';
import { getBody, getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { INotificationManage } from '../_models/notification/notificationManage.model';

const API_NAME = "notification";

export const notificationService = {
    getAll,
    get,
    submit(model: INotificationManage) { return model.id ? put(model) : post(model) },
    getCurrentUserNotifications,
    dismissUserNotification
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model: any) {

    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}

function getCurrentUserNotifications() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetCurrentUserNotifications`, requestOptions).then(handleResponse);
}

function dismissUserNotification(notificationId: any) {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/DismissUserNotification/?notificationId=${notificationId}`, requestOptions).then(handleResponse);
}