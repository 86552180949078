const local = {
  API: {
    AUTH: 'https://localhost:44348/api',
    DATA: 'https://localhost:44348/api'
  }
}

const dev = {
  API: {
    AUTH: 'https://coolmore-fhr-api.azurewebsites.net/api',
    DATA: 'https://coolmore-fhr-api.azurewebsites.net/api'
  }
}

const uat = {
  API: {
    AUTH: 'https://coolmore-fhr-api-uat.azurewebsites.net/api',
    DATA: 'https://coolmore-fhr-api-uat.azurewebsites.net/api'
  }
}

const prod = {
  API: {
    AUTH: 'https://coolmore-fhr-api.azurewebsites.net/api',
    DATA: 'https://coolmore-fhr-api.azurewebsites.net/api'
  }
}


const config = process.env.REACT_APP_STAGE === 'prod' ? prod
  : process.env.REACT_APP_STAGE === 'uat' ? uat
    : process.env.REACT_APP_STAGE === 'dev' ? dev
      : local;

const API_URL = config.API.DATA;
const AUTH_URL = config.API.AUTH;


export { API_URL, AUTH_URL };
export default config;
