import { Container, Grid, Card, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ChartPosition } from '../dashboard/dashboard-chartPosition';
import { DashboardHorseResults } from '../dashboard/dashboard-horseResults';
import { DashboardImidiateCompetition } from '../dashboard/dashboard-imidiateCompetition';
import CardBody from '../_components/Card/CardBody';
import styles from "../_components/Chart/chartStyle";
import { accountService } from '../_services';
import { useParams } from 'react-router-dom';
import { competitionUserService } from '../_services/competitionUser.service';
import { TransferButton } from '../components/transfer';
const useChartStyles = makeStyles(styles);

const useStyles = makeStyles(theme => ({
    user: {
        marginTop: "-20px",
        marginBottom: "30px",
        textAlign: "center"

    }
}));

export function UserView() {

    const chartClasses = useChartStyles();
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const [user, setUser] = useState({ name: "" });
    const { userId } = useParams<{ userId: string }>();

    useEffect(() => {
        fetchData();
    }, [userId]);


    function fetchData() {
        setLoading(true);
        return accountService.getUserName(userId)
            .then(
                (json) => {
                    setUser(json)
                },
                (e) => console.log("re2"))
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Container component="main">

                <Grid container className={classes.user}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h2 className={chartClasses.cardTitle}><strong>{user.name}</strong></h2>
                    </Grid>
                </Grid>

                <ChartPosition></ChartPosition>
                <TransferButton></TransferButton>
                <DashboardImidiateCompetition></DashboardImidiateCompetition>
                <DashboardHorseResults></DashboardHorseResults>
            </Container>
        </>
    );
}