// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { userNotificationTokenService } from "../../_services/userNotificationToken.service";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLHnB0FXmFz-o5EZ8g94cmDRBgLv0JuxM",
  authDomain: "fhrapp-7dd52.firebaseapp.com",
  projectId: "fhrapp-7dd52",
  storageBucket: "fhrapp-7dd52.appspot.com",
  messagingSenderId: "366039724065",
  appId: "1:366039724065:web:4a66efcefd6821b0ac3360",
  measurementId: "G-TKKFPLR0C4",
};

const vapid = "BGNQ0RuN-NnJ40Go2PZvsO5st_BqAxj-YvbHYb5wq2y1AmiA_HnKCnRjUU82GrRtk3GLVUbwoLCtfo-GytDOoB0";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
const analytics = getAnalytics(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: vapid })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token", currentToken);
        userNotificationTokenService.subscribe(currentToken);
        //console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ');
    });
};