import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, TablePagination } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { NotificationCreate } from '../notifications/notification-create';
import { notificationService } from '../_services/notification.service';

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export function NotificationIndex() {
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [notificationToDelete, setNotificationToDelete] = useState(0);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 5, page: 0, order: 'desc', orderBy: '' });
    const columns = [
        { title: 'Name', field: 'notificationName' },
        { title: 'Start Date', field: 'startDate', render: (rowData: any) => <>{format(new Date(rowData.startDate), 'dd/MM/yyyy HH:mm')}</> },
        { title: 'End Date', field: 'endDate', render: (rowData: any) => <>{format(new Date(rowData.endDate), 'dd/MM/yyyy HH:mm')}</> }
    ];
    const classes = useStyles();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        setLoading(true);
        return notificationService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function editNotification(event: any, rowData: any) {
        history.push(`/Notifications/Edit/${rowData.id}`)
    }

    function createNotification() {
        history.push("/Notifications/Create");
    }

    function deleteNotification(event: any, rowData: any) {
        setNotificationToDelete(rowData.id);
        setIsDialogOpen(true);
    }

    function createNewNotificationPostBack() {
        toastr.success("Notification successfully created");
        fetchData();
    }

    function updateNotificationPostBack() {
        toastr.success("Notification successfully updated");
        fetchData();
    }

    function handleDeleteCancel() {
        setIsDialogOpen(false);
    }

    function handleDeleteSubmit(rowData: any) {
        var _notification = data.items.filter(x => x.id === notificationToDelete)[0];
        _notification.isActive = false;

        notificationService
        .submit(_notification)
        .then(
            (d) => {
                toastr.success("Notification successfully deleted");
                setIsDialogOpen(false); 
                fetchData();
            },
            (e) => { console.log(e); setLoading(false); toastr.error(e || "Something went wrong. Please try again later") }
        );
    }

    return (
        <>
            <Container component="main">

                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Notifications' icon='notifications'></PageHeader>
                    </div>

                    <MaterialTable
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow><TableRow></TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}

                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Notification',
                                isFreeAction: true,
                                onClick: (event) => createNotification()
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit Notification',
                                onClick: editNotification
                            },
                            {
                              icon: 'delete',
                              tooltip: 'Delete Notification',
                              onClick: deleteNotification
                            }
                        ]}

                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                        }}


                    />
                </Paper>
            </Container>

            <Route path="/Notifications/Create" component={() => <NotificationCreate successCallback={createNewNotificationPostBack}></NotificationCreate>} />
            <Route path="/Notifications/Edit/:notificationId" component={() => <NotificationCreate successCallback={updateNotificationPostBack}></NotificationCreate>} />

            <div>
                    <Dialog
                        open={isDialogOpen}
                        onClose={(e) => setIsDialogOpen(false)}
                        aria-labelledby="draggable-dialog-title"
                    >

                        <DialogContent>
                            <DialogContentText>
                                    Are you sure you want to delete this notification?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleDeleteCancel} color="secondary">
                                Cancel
                            </Button>

                            <Button disabled={isLoading} variant="contained" type="button" onClick={handleDeleteSubmit} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
        </>
    );
}