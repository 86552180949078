import { Container, Paper, TablePagination, colors } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { history } from '../_helpers/history';
import { uploadsService } from '../_services/uploads.service';
import { UploadsCreate } from './uploads-create';
import { RED } from '../_theme/colors';


interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export function UploadsIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 5, page: 0, order: 'desc', orderBy: 'DateUploaded' });

    const classes = useStyles();

    const columns = [
        { title: 'File Name', field: 'fileName' },
        { title: 'Uploaded By', field: 'uploadedBy' },
        { title: 'Date Uploaded', field: 'dateUploaded', render: (rowData: any) => <>{format(new Date(rowData.dateUploaded), 'dd/MM/yyyy hh:mm')}</> },
        { title: 'State', field: 'state' },
    ];

    useEffect(() => { fetchData() }, []);

    function fetchData() {
        setLoading(true);
        return uploadsService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function goToFile(uploadId: string, fileTypeId: number) {
        if (fileTypeId === 1) {
            history.push("/Upload/Horses/" + uploadId)
        }
        else if (fileTypeId === 2) {
            history.push("/Upload/RaceResults/" + uploadId)
        }
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function createUploadPostBack(): void { fetchData(); }


    function createUpload() {
        history.push("/Uploads/Create")
    }

    return (
        <>
            <Container component="main">


                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Uploads' icon='cloud_upload'></PageHeader>
                    </div>

                    <MaterialTable
                        onRowClick={(e: any, data: any) => goToFile(data.id, data.fileTypeId)}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Create Upload',
                                isFreeAction: true,
                                onClick: (event) => createUpload()
                            }
                        ]}
                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            rowStyle: rowData => ({
                                backgroundColor: rowData.hasErrors ? RED : ""
                            })
                        }}


                    />
                </Paper>
            </Container>

            <Route path="/Uploads/Create" component={() => <UploadsCreate successCallback={createUploadPostBack}></UploadsCreate>} />
        </>
    );
}