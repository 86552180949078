import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { notificationService } from '../_services/notification.service';
import { INotificationManage } from '../_models/notification/notificationManage.model';
import Grid from '@material-ui/core/Grid';
import { Button, Container, Icon, makeStyles } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    notificationSpacing: {
        marginBottom: theme.spacing(1)
    },
    closeButton: {
        float: "right!important" as any,
        paddingTopt: theme.spacing(1)
    }
}));

export function NotificationBanner() {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const classes = useStyles();

    function fetchData() {
        setIsLoading(true);
        notificationService.getCurrentUserNotifications()
            .then(res => {
                setNotifications(res);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => { fetchData() }, []);

    function getNotificationRow(notification: any, index: number) {
        return <><Grid item xs={12} className="alert alert-warning">
            <strong>{notification.notificationName}</strong>
            <Button onClick={e => dismissNotification(notification.id)} className={classes.closeButton}>
                <Icon >close</Icon>
            </Button>
        </Grid><Grid item xs={12}></Grid></>
    }

    function dismissNotification(notificationId: number) {
        notificationService.dismissUserNotification(notificationId)
            .then((res: INotificationManage) => {
                let _notifications = notifications.filter((x: INotificationManage) => x.id !== notificationId);
                setNotifications(_notifications);
            })
            .finally(() => setIsLoading(false));
    }
    return (
        <>
            {(isLoading === false && notifications && notifications.length > 0) &&
                <Container component="main">
                    <Grid container spacing={2} className={classes.notificationSpacing}>
                        {notifications.map((n: INotificationManage, index: number) =>
                            <Grid item xs={12}>
                                <Alert severity="info"
                                    onClose={() => { dismissNotification(n.id) }}>
                                    <AlertTitle>{n.notificationName}</AlertTitle>
                                    <div dangerouslySetInnerHTML={{ __html: n.notificationText }} />
                                </Alert>
                            </Grid>
                        )}

                    </Grid>
                </Container>
            }
        </>
    );
}