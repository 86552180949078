import { Container, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TransferButton } from '../components/transfer';
import { Rules } from '../rules/rules';
import { competitionUserService } from '../_services/competitionUser.service';
import { ChartPosition } from './dashboard-chartPosition';
import { DashboardHorseResults } from './dashboard-horseResults';
import { DashboardImidiateCompetition } from './dashboard-imidiateCompetition';
import { DashboardOngoingCompetitions } from './dashboard-ongoingCompetitions';

interface User {
    competitionId?: number;
}


export function DashboardIndex() {

    //competitionUserService.any().then()

    const [isLoading, setLoading] = useState(true);
    const [anyCompetitions, setCompetition] = useState(null);
    function fetchData() {
        return competitionUserService.any()
            .then(json => {
                setCompetition(json);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <Container component="main">

                {isLoading &&
                    <LinearProgress />
                }


                {(!isLoading && anyCompetitions == false) &&
                    <>
                        <DashboardOngoingCompetitions></DashboardOngoingCompetitions>
                        <Rules></Rules>
                    </>
                }

                {(!isLoading && anyCompetitions == true) &&
                    <>
                        {/* <NotPaidBanner></NotPaidBanner> */}
                        <ChartPosition></ChartPosition>
                        <TransferButton></TransferButton>
                        <DashboardImidiateCompetition></DashboardImidiateCompetition>
                        <DashboardHorseResults></DashboardHorseResults>
                    </>
                }
                <i className="fa fa-horse-head"></i>
            </Container>
        </>
    );
}