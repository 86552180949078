import { Button, Container, Grid, Paper, TablePagination, TextField } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../common/pageHeader';
import { raceResultService } from '../_services/raceResult.service';
interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
    fromDate: string;
    toDate: string;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    marginTop: {
        marginTop: "50px"
    },
    heading: {
        marginTop: "-20px",
        marginBottom: "30px",
        textAlign: "center"
    }
}));

export function ResultsIndex() {
    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: 'RaceDate', fromDate: '', toDate: '' });
    const [tableKey, setTableKey] = React.useState(0);

    const columns = [
        { title: 'Race Date', field: 'raceDate', render: (rowData: any) => <>{format(new Date(rowData.raceDate), 'dd/MM/yyyy')}</> },
        { title: 'Course Name', field: 'raceCourse' },
        { title: 'Fin Pos', field: 'finishPosition' },
        { title: 'Horse Name', field: 'horseName' },
        { title: 'Trainer', field: 'trainer' },
        { title: 'Race Type', field: 'raceType' },
    ];

    const classes = useStyles();

    useEffect(() => {
        fetchData();
    }, []);

    const handleFromDateChange = (event: any) => {
        const _filter = filter;
        _filter.fromDate = (event.target.value);

        setFilter(_filter);
        fetchData();
    };

    const handleToDateChange = (event: any) => {
        const _filter = filter;
        _filter.toDate = (event.target.value);

        setFilter(_filter);
        fetchData();
    };

    const handleSearchChange = (event: any) => {
        setFilter(_filter => { return { ..._filter, search: event.target.value } });
    };


    const handleClearFilters = (event: any) => {
        const _filter = filter;
        filter.search = '';
        filter.fromDate = '';
        filter.toDate = '';
        setFilter(_filter);
        setTableKey(prevTableKey => prevTableKey + 1); // Will cause table to remount
        fetchData();
    };

    function fetchData() {
        setLoading(true);
        return raceResultService.getResultsForActiveCompetition(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        console.log("change");
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    return (
        <>
            <Container component="main">
                <Paper>
                    <CssBaseline />
                    <div className={classes.pageHeader}>
                        <PageHeader title='Results' icon='star_border'></PageHeader>

                        <Grid container style={{ "marginLeft": "10px", "width": "calc(100% - 20px)" }}>
                            <Grid item xs={6} sm={4} md={3}>
                                <TextField
                                    type="date"
                                    value={filter.fromDate}
                                    onChange={handleFromDateChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3}>

                                <TextField
                                    type="date"
                                    value={filter.toDate}
                                    onChange={handleToDateChange}
                                />
                            </Grid>

                            <Grid item xs={6} sm={4} md={3}>

                                <TextField
                                    type="text"
                                    key={"search_key"}
                                    placeholder="Search"
                                    value={filter.search}
                                    onBlur={fetchData}
                                    onChange={handleSearchChange}
                                />
                            </Grid>
                            <Grid item xs={6} sm={12} md={3}>

                                <Button variant="outlined" style={{ color: "#e65e7b", width: "100%" }} onClick={handleClearFilters}>Clear Filters</Button>
                            </Grid>

                        </Grid>

                    </div>



                    <MaterialTable
                        key={tableKey}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,

                        }}
                        options={{
                            search: false,
                            toolbar: true,
                            initialPage: 0,
                            pageSize: filter.limit,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false
                        }}



                    />
                </Paper>
            </Container>
        </>
    )
}

