import { Button, Checkbox, FormControlLabel, Icon } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import React, { useEffect, useRef, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { INotificationManage } from '../_models/notification/notificationManage.model';
import { useDrawerStyles } from '../_styles';
import { getDateString } from '../_helpers/date.helper';
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Switch from '@material-ui/core/Switch';
import { notificationService } from '../_services/notification.service';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';


export function NotificationCreate(params: { successCallback: () => void }) {

    const { notificationId } = useParams<{ notificationId: string }>();
    const [isLoading, setLoading] = useState(false);
    const [notificationText, setNotificationText] = useState('');
    const [notification, setNotification] = useState<INotificationManage>(
        {
            id: +(notificationId || 0),
            notificationName: '',
            startDate: new Date(),
            endDate: new Date(),
            notificationText: '',
            isActive: true
        });

    const classes = useDrawerStyles();

    useEffect(() => {
        fetchData()
    }, [notificationId]);

    function fetchData() {
        if (notification.id > 0) {
            return notificationService.get(notification.id)
                .then(res => {
                    setNotificationText(res.notificationText);
                    setNotification(res);
                });
        }
    }

    function navigateBack() {
        history.push('/notifications');
    };

    function submit(e: any) {
        e.preventDefault();
        setLoading(true);

        let _notification = notification;
        _notification.notificationText = notificationText;

        if(notificationId !== null) {
            let user = JSON.parse(localStorage.getItem('currentUser') ?? '');
            _notification.createdBy = user.email;
        }

        notificationService
            .submit(_notification)
            .then(
                (d) => { console.log(d); params.successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error(e || "Something went wrong. Please try again later") }
            );
    }

    function handleNameChange(e: any) {
        let _notification = { ...notification };
        _notification.notificationName = e.target.value;
        setNotification(_notification)
    };

    function handleStartDateChange(d: any) {
        let _notification = { ...notification };
        _notification.startDate = d;
        setNotification(_notification);
    };

    function handleEndDateChange(d: any) {
        let _notification = { ...notification };
        _notification.endDate = d;
        setNotification(_notification);
    };

    function handleNotificationTextChange(t: any) {
        setNotificationText(t);
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">
                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">
                        <div className={classes.heading}>
                            <h4>
                                Create Notification

                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>
                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Notification Name"
                                name="notificationName"
                                value={notification.notificationName}
                                disabled={isLoading}
                                onChange={handleNameChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                            />
                            <Grid container spacing={2}>
                                <Grid container item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DateTimePicker
                                            label="Notification Start Date"
                                            ampm={false}
                                            inputVariant="outlined"
                                            name="startDate"
                                            value={notification.startDate}
                                            onChange={handleStartDateChange}
                                            required
                                            className={classes.form}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid container item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DateTimePicker
                                    label="Notification End Date"
                                    ampm={false}
                                    inputVariant="outlined"
                                    name="endDate"
                                    value={notification.endDate}
                                    onChange={handleEndDateChange}
                                    required
                                    className={classes.form}
                                />
                                        </MuiPickersUtilsProvider>
                                        </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid container item xs={12}>
                                    <SunEditor
                                        height={400}
                                        setContents={notificationText}
                                        onChange={handleNotificationTextChange}
                                    />
                                    </Grid>
                                </Grid>
                            <Grid container>
                                <Grid container item xs={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                        disableElevation
                                        className={classes.submit}
                                    >
                                        <Icon>check</Icon> Save
                                    </Button>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disabled={isLoading}
                                        disableElevation
                                        className={classes.submit}
                                        onClick={navigateBack}
                                    >
                                        <Icon>close</Icon> Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </div>
                </div>
            </Drawer>
        </>
    );
}
