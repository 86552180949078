import { API_URL } from '../_config/const';
import { getHeader, getQuery, getBody } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "horse";

export const horsesService = {
    getAll,
    get,
    getByCategory,
    getAllForFile,
    put,
    disable
};

function getByCategory(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetByCategory/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function getAllForFile(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAllForFile/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function put(id: any, model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function disable(id: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
/*
function post(model) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(id, model) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
*/