import { API_URL } from '../_config/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "competitionUserHorseScoring";

export const competitionUserHorseScoreService = {
    getAll,
    getHorseGraphDataPerUserUpload
};

function getAll(userId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?userId=${userId}`, requestOptions).then(handleResponse);
}

function getHorseGraphDataPerUserUpload(userId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetHorseGraphDataPerUserUpload/?userId=${userId}`, requestOptions).then(handleResponse);
}
