import { Button, Icon, makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { accountService } from '../_services';
import { useDrawerStyles } from '../_styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from '@material-ui/core/Switch';

export function AccountEdit({ successCallback }) {

    const [isLoading, setLoading] = useState(false);
    const [account, setAccount] = useState({ name: '', email: '', password: '', role: '', department: ''});
    const [receiveNotificationEmails, setReceiveNotificationEmails] = useState(true);
    const classes = useDrawerStyles();
    const { accountId } = useParams();

    function navigateBack() {
        history.push('/Accounts');
    };

    useEffect(() => { fetchData() }, []);

    function fetchData() {
        return accountService.get(accountId)
            .then(data => {
                data.role = data.roles;
                setReceiveNotificationEmails(data.receiveNotificationEmails);
                setAccount(data);
            });
    }

    function submit(e) {
        e.preventDefault();
        setLoading(true);

        var _account = account;
        _account.roles = [account.role];
        _account.receiveNotificationEmails = receiveNotificationEmails;
        accountService
            .put(accountId, account)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function confirmRegistration(e) {
        e.preventDefault();
        setLoading(true);
        accountService
            .confirmAccount(accountId)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function disable(e) {
        e.preventDefault();
        setLoading(true);
        accountService
            .disable(accountId)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function handleChange(event) {
        var _account = account;
        _account[event.target.name] = event.target.value;
        setAccount({ ..._account });
    };

    function handleReceiveNotificationsChange() {
        setReceiveNotificationEmails(prev => !prev);
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">

                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">

                        <div className={classes.heading}>
                            <h4>
                                {!account.registrationConfirmed && <span>Confirm Account</span>}
                                {account.registrationConfirmed && <span>Update Account</span>}
                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>

                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate autocomplete="off">
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="User Name"
                                autoComplete='off'
                                name="name"
                                value={account.name}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                                disabled={account.registrationConfirmed === false ? true : false }
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Department"
                                name="department"
                                value={account.department}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                disabled={account.registrationConfirmed === false ? true : false }
                            />

                            {account.registrationConfirmed && <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Password"
                                name="password"
                                value={account.password}
                                disabled={isLoading}
                                onChange={handleChange}
                                helperText="Only Update if password change is required. At least 8 chars, 1 special char and 1 capital"
                                type="password"
                            />}

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                value={account.email}
                                disabled={true}
                            />

                            {account.registrationConfirmed && <SelectValidator labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={account.role}
                                onChange={handleChange}
                                required
                                label="Select Role"
                                name="role"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined">
                                <MenuItem value='Admin'>Admin</MenuItem>
                                <MenuItem value='User'>User</MenuItem>
                            </SelectValidator>}
                            <FormControlLabel
                                control={<Switch checked={receiveNotificationEmails} onChange={handleReceiveNotificationsChange} />}
                                label="Receive notification emails?"
                            />
                            
                            <Grid container spacing={0}>
                                <Grid container item spacing={0} xs={account.isActive === false ? 12 : 6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                        disableElevation
                                        className={classes.submit}
                                        onClick={account.registrationConfirmed === true ? submit : confirmRegistration}
                                    >
                                        {account.registrationConfirmed && <><Icon>check</Icon> {account.isActive === false ? <>Save and Reactivate</> : <>Save</>}</>}
                                        {!account.registrationConfirmed && <><Icon>check</Icon>Confirm Registration</>}
                                    </Button>
                                </Grid>
                                {(account.isActive === true) &&
                                    <Grid container item spacing={0} xs={6}>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={isLoading}
                                            disableElevation
                                            className={classes.submit}
                                            onClick={account.registrationConfirmed === true ? disable : navigateBack}
                                        >
                                            {account.registrationConfirmed && <><Icon>remove</Icon> Disable</>}
                                            {!account.registrationConfirmed && <><Icon>close</Icon>Cancel</>}
                                    </Button>
                                    </Grid>
                                }
                            </Grid>
                        </ValidatorForm>
                    </div>
                </div>
            </Drawer>
        </>
    );
}