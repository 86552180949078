import { Button, Icon, makeStyles, TextField } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { uploadsService } from '../_services/uploads.service';


const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-4),
        marginRight: theme.spacing(-1),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    closeButton: {
        float: "right",
        paddingTopt: theme.spacing(1)
    },
    drawer: {
        padding: theme.spacing(1)
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    spacer: {
        marginBottom: theme.spacing(1)
    }
}));

export function UploadsCreate({ successCallback }: { successCallback: () => void }) {

    const [isLoading, setLoading] = useState(false);
    const [upload, updateUpload] = useState<{ fileTypeId: Number, file: any | null }>({ fileTypeId: 0, file: null });
    const classes = useStyles();

    function navigateBack() {
        history.push('/Uploads');
    };

    function submit(e: any) {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("file", upload.file);
        formData.append("fileTypeId", upload.fileTypeId.toString());

        uploadsService
            .post(formData)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error(e || "Something went wrong. Please try again later") }
            )
            .finally(() => { setLoading(false) });
    }

    function handleChange(event: any) {
        const _upload = upload;
        _upload.fileTypeId = event.target.value;
        updateUpload({ ..._upload })
    };

    function onUploadChange(event: any) {
        const _upload = upload
        _upload.file = event.target.files[0];
        updateUpload({ ..._upload });
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">

                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">

                        <div className={classes.heading}>
                            <h4>
                                Upload

                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>

                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate autocomplete="off">

                            <TextField
                                id="outlined-number"
                                label="Upload"
                                type='file' onChange={onUploadChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.spacer}
                                variant="outlined"
                            />

                            <SelectValidator labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={upload.fileTypeId}
                                onChange={handleChange}
                                required
                                label="Select Type"
                                name="fileTypeId"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined">
                                <MenuItem value='1'>Horse Upload</MenuItem>
                                <MenuItem value='2'>Results Upload</MenuItem>
                            </SelectValidator>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={upload.file == null || upload.fileTypeId == 0 || isLoading}
                                disableElevation
                                className={classes.submit}
                            >
                                <Icon>check</Icon> Save
                            </Button>

                        </ValidatorForm>
                    </div>
                </div>
            </Drawer>
        </>
    );
}