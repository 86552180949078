import { Container, Icon, Paper, TablePagination, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../common/pageHeader';
import { history } from '../_helpers/history';
import { competitionScoreService } from '../_services/competitionScore.service';
import { reportService } from '../_services/report.service';
import { API_URL } from '../_config/const';
import { accountService } from '../_services';
import { toastr } from '../_components/toastr';


interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    gold: {
        color: "#FFD700"
    },
    silver: {
        color: "silver"
    },
    bronze: {
        color: "#cd7f32"
    }
}));

export function StandingsIndex() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'asc', orderBy: 'position' });


    const classes = useStyles();

    let columns = [
        { title: 'Position', field: 'position', render: (rowData: any) => rowData.position < 4 ? <><Icon className={(rowData.position == 1) ? classes.gold : (rowData.position == 2) ? classes.silver : classes.bronze}>emoji_events</Icon></> : <>{rowData.position}{rowData.positionString}</> },
        { title: 'Prize', field: 'prizeAmount', render: (rowData: any) => <>{currencyFormatter.format(rowData.prizeAmount)}</> },
        { title: 'User', field: 'email' },
        { title: 'Points', field: 'points', render: (rowData: any) => rowData.points || 0 },
        { title: 'Department', field: 'department' },
    ];
    // Dont show prize column to users only admin
    if(!accountService.isAdmin)
        columns = columns.filter(x => x.title !== 'Prize');

    var currencyFormatter = new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR', });

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        setLoading(true);
        return competitionScoreService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function goToUser(userId: string) {
        history.push("/User/" + userId)
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function setPropNoReloading(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function downloadReport() {

        setDisabled(true);
        setLoading(true);

        return reportService.getReportToken()
            .then(json => {
                window.location.href = `${API_URL}/report/GetStandingsReport/?token=${json}`;
            },
            err => toastr.error("Join the competition to see standings"))
            .finally(() => setLoading(false));
    }


    return (
        <>
            <Container component="main">


                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Standings' icon='emoji_events'></PageHeader>


                        <Button fullWidth onClick={downloadReport} disabled={isDisabled} variant="contained" color="primary">
                            {!isDisabled && <>Print Current Standings</>}
                            {isDisabled && <>Downloading...</>}
                        </Button>
                    </div>

                    <MaterialTable
                        onRowClick={(e: any, data: any) => goToUser(data.userId)}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}

                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            initialPage: 0,
                            pageSize: filter.limit,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false
                        }}


                    />
                </Paper>
            </Container>
        </>
    );
}