import { API_URL } from '../_config/const';
import { getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "fileUploadEntry";

export const fileUploadEntryService = {
    getAll,
};

function getAll(uploadId: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?uploadId=${uploadId}`, requestOptions).then(handleResponse);
}