import { API_URL } from '../_config/const';
import { getHeader, getQuery, getBody } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "raceResult";

export const raceResultService = {
    getAll,
    removeRaceResult,
    getResultsForActiveCompetition
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    console.log(`${API_URL}/${API_NAME}/?${getQuery(query)}`);
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function removeRaceResult(raceResultId: any) {
    const requestOptions = { method: 'POST', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/RemoveRaceResult/?id=${raceResultId}`, requestOptions).then(handleResponse);
}

function getResultsForActiveCompetition(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetResultsForActiveCompetition/?${getQuery(query)}`, requestOptions).then(handleResponse);
}
/*
function post(model) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(id, model) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
*/