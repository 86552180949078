import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { getBody, getBodyWithArray } from '../_helpers/body-helper';
import { ICompetitionManage } from '../_models';

const API_NAME = "competition";

export const competitionService = {
    getAll,
    get,
    post,
    submit(model: ICompetitionManage) { return model.id ? put(model) : post(model) },
    getAllOngoingCompetitions
};

function getAllOngoingCompetitions() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/OngoingCompetitions`, requestOptions).then(handleResponse);
}

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function get(id: number) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBodyWithArray(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(model: any) {

    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBodyWithArray(model) };
    return fetch(`${API_URL}/${API_NAME}`, requestOptions).then(handleResponse);
}

/*
function remove(id) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
*/