import { Container, Grid, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import ChartistGraph from "react-chartist";
import { useParams } from 'react-router-dom';
import Card from "../_components/Card/Card.js";
import CardBody from "../_components/Card/CardBody.js";
import CardFooter from "../_components/Card/CardFooter.js";
import CardHeader from "../_components/Card/CardHeader.js";
import { emailsSubscriptionChart } from "../_components/Chart/chartData.js";
import styles from "../_components/Chart/chartStyle";
import { history } from '../_helpers/history';
import { competitionHorseScoringService } from "../_services/competitionHorseScoring.service";
import { HorseChartPosition } from './horses-chartPosition';

const useChartStyles = makeStyles(styles);

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    horseId: any;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    button: {
        margin: theme.spacing(1),
        flex: 1,
        width: "300px"
    }
}));

export function HorseView() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 50, page: 0, order: 'desc', orderBy: '', horseId: 0 });

    const [horsePointsPerUpload, setHorsePointsPerUpload] = useState({ labels: [], series: [[]] });
    const [userHorsePointsPerUpload, setUserHorsePointsPerUpload] = useState({ labels: [], series: [[]] });
    const [userHorsePointsPerUploadOptions, setUserHorsePointsPerUploadOptions] = useState(emailsSubscriptionChart.options);

    const [stateChnage, setStateChange] = useState("012312312313");

    const { horseId } = useParams<{ horseId: string }>();

    function fetchData() {

        filter.horseId = horseId;

        setLoading(true);
        return competitionHorseScoringService.get(filter)
            .then(
                (json) => {
                    setData(json)
                },
                (e) => console.log("re3"))
            .finally(() => setLoading(false));
    }

    const columns = [
        { title: 'Points', field: 'points' },
        { title: 'Race Name', field: 'courseName' },
        { title: 'Race Result Date', field: 'raceResultDate', render: (rowData: any) => <>{(rowData.raceResultDate) ? format(new Date(rowData.raceResultDate), 'dd/MM/yyyy hh:mm') : '-'}</> },
        { title: 'Race Type', field: 'raceTypeName' },
        { title: 'Position', field: 'finishPosition' },
    ];


    const classes = useStyles();
    const chartClasses = useChartStyles();

    function goToHorse(horseId: string) {
        history.push("/Horses/" + horseId);
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function fetchHorseGraphData() {
        return competitionHorseScoringService.getHorseGraphDataPerUserUpload((horseId || ""))
            .then(
                (json) => {
                    let _horsePointsPerUpload = horsePointsPerUpload;

                    _horsePointsPerUpload.labels = json.map((m: any) => m.label);
                    _horsePointsPerUpload.series = [json.map((m: any) => m.totalPoints)];

                    setHorsePointsPerUpload(_horsePointsPerUpload);
                    setStateChange("sate change");
                },
                (e) => console.log("re2"));
    }

    useEffect(() => {
        fetchData();
        fetchHorseGraphData();
    }, [horseId]);

    return (
        <Container component="main">

            <HorseChartPosition></HorseChartPosition>

            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card chart>
                        {/*
                        <CardHeader color="warning">
                            <ChartistGraph
                                className="ct-chart"
                                data={horsePointsPerUpload}
                                type="Bar"
                                options={userHorsePointsPerUploadOptions}
                            />

                        </CardHeader>
                        */}
                        <CardBody>
                            <h4 className={chartClasses.cardTitle}>Latest Results</h4>
                        </CardBody>
                        <CardFooter chart >
                            <div className={chartClasses.stats}>
                            </div>
                        </CardFooter>

                        <MaterialTable
                            columns={columns}
                            data={data.items}
                            onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                            isLoading={isLoading}
                            components={{
                                Pagination: (componentProps) => <TablePagination
                                    {...componentProps}
                                    count={data.count}
                                    onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                    onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                    page={filter.page}
                                    rowsPerPage={filter.limit}
                                />,
                                Header: d =>
                                    <TableHead>
                                        <TableRow>
                                            {columns && columns.map(h =>
                                                <TableCell key={h.title}
                                                    sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                    <Tooltip
                                                        title="Sort"
                                                        enterDelay={300}>
                                                        <TableSortLabel
                                                            active={filter.orderBy === h.field}
                                                            direction={filter.order}
                                                            onClick={e => handleRequestSort(h.field)}>
                                                            {h.title}
                                                        </TableSortLabel>
                                                    </Tooltip>
                                                </TableCell>
                                            )}
                                        </TableRow>

                                    </TableHead>
                            }}

                            options={{
                                search: true,
                                toolbar: true,
                                pageSize: filter.limit,
                                pageSizeOptions: [5, 10, 20, 50, 100],
                                initialPage: 0,
                                showEmptyDataSourceMessage: true,
                                sorting: true,
                                showTitle: false,
                                loadingType: 'linear',
                                debounceInterval: 200,
                                doubleHorizontalScroll: true,
                                emptyRowsWhenPaging: false
                            }}
                        />
                    </Card>
                </Grid>

            </Grid>


        </Container>
    );
}

