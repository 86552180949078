import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';


export function Footer() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://hcs.ie/">
                HCS Software Solutions
      </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}