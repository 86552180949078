import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "report";

export const reportService = {
    getReportToken
};

function getReportToken() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetReportToken/`, requestOptions).then(handleResponse);
}

/*
function post(model) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function put(id, model) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
*/