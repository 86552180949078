import { Container, Paper, TablePagination } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { competitionService } from '../_services/competition.service';
import { CompetitionCreate } from './competition-create';


interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export function CompetitionIndex() {

    const hasLoaded = React.useRef(false);
    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 5, page: 0, order: 'desc', orderBy: '' });

    const classes = useStyles();

    const columns = [
        { title: 'Name', field: 'competitionName' },
        { title: 'Fee', field: 'entryFee' },
        { title: 'Valid From', field: 'validFrom', render: (rowData: any) => <>{format(new Date(rowData.validFrom), 'dd/MM/yyyy hh:mm')}</> },
        { title: 'Valid To', field: 'validTo', render: (rowData: any) => <>{format(new Date(rowData.validTo), 'dd/MM/yyyy hh:mm')}</> }
    ];


    useEffect(() => {
        fetchData();

        return () => {
            hasLoaded.current = true;
        }
    }, []);

    function fetchData() {
        setLoading(true);
        return competitionService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function createNewCompetitionPostBack() {
        toastr.success("Competition successfully created");
        fetchData();
    }

    function createCompetition() {
        history.push("/Competitions/Create");
    }

    function goToCompetitionUsers(id: any) {
        history.push("/Competition/" + id + "/Users");
    }

    function editCompetition(event: any, rowData: any) {
        history.push(`/Competitions/Edit/${rowData.id}`)
    }

    return (
        <>
            <Container component="main">

                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Competitions' icon='compare_arrow'></PageHeader>
                    </div>

                    <MaterialTable
                        onRowClick={(e: any, data: any) => goToCompetitionUsers(data.id)}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow><TableCell></TableCell>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}

                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add Competition',
                                isFreeAction: true,
                                onClick: (event) => createCompetition()
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit Competition',
                                onClick: editCompetition
                            }
                        ]}

                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                        }}


                    />
                </Paper>
            </Container>

            <Route path="/Competitions/Create" component={() => <CompetitionCreate successCallback={createNewCompetitionPostBack}></CompetitionCreate>} />
            <Route path="/Competitions/Edit/:competitionId" component={() => <CompetitionCreate successCallback={createNewCompetitionPostBack}></CompetitionCreate>} />

        </>
    );
}