import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { competitionUserHorseScoreService } from '../_services/competitionUserHorseScore.service';
import { competitionUserService } from '../_services/competitionUser.service';
import { format } from 'date-fns';
import { history } from '../_helpers/history';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { CssBaseline } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { PageHeader } from '../common/pageHeader';
import MaterialTable from 'material-table';
import { Chip, Container, Grid, Icon, makeStyles, Paper, TablePagination } from '@material-ui/core';
import { horsesService } from '../_services/horse.service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { toastr } from '../_components/toastr';
import { HorseCategoryEnum } from '../common/enum/horseCategory.enum';

interface ITableData {
    items: Array<any>;
    count: number;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
    category?: 'two' | 'threeUp';
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    button: {
        margin: theme.spacing(1),
        flex: 1,
        width: "300px"
    },
    page: {
        marginBottom: "50px"
    },
    main: {
        paddingBottom: "100px"
    },
    transferIn: {
        margin: theme.spacing(1),
        color: "green"
    },
    transferOut: {
        margin: theme.spacing(1),
        color: "red"
    }
}));

const options: any = [
    { name: 'Select Horse', step: 'Select Horse Out', title: 'Please Select Horse To Transfer Out', itemsTitle: 'Selected Horse To Transfer Out' },
    { name: 'Select Horse', step: 'Select Horse In', title: 'Please Select Horse To Transfer In', itemsTitle: 'Selected Horse To Transfer In' },
    { name: 'Confirm Transfer', step: 'Confirm Transfer', title: 'Please Confirm Transfer', itemsTitle: 'Confirm Transfer' }
];

const noHorseSelectedSteps = ['Select Horse to Transfer out', 'Select Horse to Transfer in', 'Confirm'];

export function CompetitionTransfer() {
    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [transferOutData, setTransfeOutData] = useState<ITableData>({ items: [], count: 0 });
    const [transferInData, setTransferInData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const { userId } = useParams<{ userId: string }>();
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 1000, page: 0, order: 'desc', orderBy: '', category: options[0].category });
    const [selectedHorses, setSelectedHorses] = useState<any>([]);
    const [steps, setSteps] = useState(noHorseSelectedSteps);

    function handleRequestSort(property: string) {
        console.log('sorting by: ', property);
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
    }

    const columns = [
        [
            { title: 'Name', field: 'horseName' },
            { title: 'Category', field: 'categoryName' },
            { title: 'Sex', field: 'sex' },
            { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth) ? format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy') : '-'}</> },
        ],
        [
            { title: 'Name', field: 'name' },
            { title: 'Category', field: 'category' },
            { title: 'Sex', field: 'sex' },
            { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth) ? format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy') : '-'}</> },
        ]
    ];

    useEffect(() => {
        fetchTransferOutData();
        fetchTransferInData();
        fetchTransferTokens();
    }, [userId]);

    const classes = useStyles();
    
    function fetchTransferOutData() {
        setLoading(true);
        return competitionUserHorseScoreService.getAll((userId || ""))
            .then(
                (json) => {
                    var _newItems = json;
                    _newItems.items = json.filter((x: any) => x.isActive === true);
                    _newItems.count = _newItems.items.length;
                    setData(_newItems);
                    setTransfeOutData(_newItems);
                },
                (e) => console.log("re2"))
            .finally(() => setLoading(false));
    }

    function fetchTransferInData() {
        return horsesService.getByCategory('')
            .then(json => {
                setTransferInData(json);
            })
            .finally();
    }

    function fetchTransferTokens() {
        return competitionUserService.getTransferTokens(userId)
        .then(
            (res) => {
                // Just in case they save the link and browse to it
                if(res === 0)
                    goToUser();
            },
            (e) => console.log("re3"));
    }

    function handleNext() {
        if(activeStep < 1) {
            var _transferInData = { ...transferInData };

            if(selectedHorses[0].categoryName === HorseCategoryEnum.OlderRacehorse ||
                selectedHorses[0].categoryName === HorseCategoryEnum.ThreeYORacehorse) {
                _transferInData.items = transferInData.items.filter(x => (x.category === HorseCategoryEnum.OlderRacehorse ||
                                                                        x.category === HorseCategoryEnum.ThreeYORacehorse)  &&
                                                                    data.items.findIndex(y => y.horseId === x.id) === -1);
            } else {
                _transferInData.items = transferInData.items.filter(x => x.category === selectedHorses[0].categoryName &&
                                                                    data.items.findIndex(y => y.horseId === x.id) === -1);   
            }
            _transferInData.count = _transferInData.items.length;
            setData(_transferInData);
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        } else {
            setIsDialogOpen(true);
        }
    };

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
        removeHorse();
        setData(transferOutData);
    };

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
    }

    function handleSubmit() {
        const submitModel = {userId, transferOutHorseId: selectedHorses[0].horseId, transferInHorseId: selectedHorses[1].id}
        setLoading(true);
        return competitionUserService.transferHorse(submitModel)
            .then(
                (json) => {
                    setIsDialogOpen(false);
                    toastr.success("Transfer complete");
                    goToUser();
                },
                (e) => { toastr.error("Something went wrong. Please try again later");})
            .finally(() => setLoading(false));
    }

    function handleCancel() {
        setIsDialogOpen(false);
    }

    function isAdded(data: any) {
        // Models are different
        return activeStep == 0 ? selectedHorses[activeStep] !== undefined && selectedHorses[activeStep].horseId === data.horseId
                                : selectedHorses[activeStep] !== undefined && selectedHorses[activeStep].id === data.id;
    }

    function selectHorse(e: any, data: any) {
        var _selectedHorses = selectedHorses;
        if (isAdded(data)) {
            removeHorse();
        } else {
            _selectedHorses[activeStep] = data;

            var _steps = [...steps];
            _steps[activeStep] = '';
            setSteps(_steps);
        }

        setSelectedHorses(_selectedHorses);
    }


    function removeHorse() {
        var _allSelectedHorses = selectedHorses;
        _allSelectedHorses.pop();
        var _steps = [...steps];
       
        if(_allSelectedHorses.length === 0) {
            _steps[0] = noHorseSelectedSteps[0];
        } else {
            _steps[1] = noHorseSelectedSteps[1];
        } 
        
        setSteps(_steps);
        setSelectedHorses(_allSelectedHorses);
    }

    function goToUser() {
        history.push("/User/" + userId);
    }

    const orientation: "horizontal" | "vertical" = window.innerWidth < 760 ? "vertical" : "horizontal";

    return (
        <>
            <Container component="main" className={classes.main}>
                <Paper className={classes.page}>
                    <Stepper activeStep={activeStep} orientation={orientation}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: { optional?: React.ReactNode } = {};

                            if(selectedHorses[0] !== undefined && index === 0)
                                labelProps.optional = <Chip key={selectedHorses[0]?.horseId} className={classes.transferOut} onDelete={(e) => removeHorse()} label={selectedHorses[0]?.horseName} icon={<Icon>remove</Icon>} disabled={activeStep === 1}></Chip>;
                            else if(selectedHorses[1] !== undefined && index === 1)
                                labelProps.optional = <Chip key={selectedHorses[1]?.horseId} className={classes.transferIn} onDelete={(e) => removeHorse()} label={selectedHorses[1]?.name} icon={<Icon>check</Icon>}></Chip>;
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <Grid container spacing={0}>

                        {(activeStep > 0) &&
                            <Grid item xs={4}>
                                <Button variant="contained" type="button" fullWidth color="secondary" onClick={handleBack}>
                                    Back
                                </Button>
                            </Grid>
                        }

                        {(activeStep <= 1) &&
                            <Grid item xs={activeStep == 0 ? 12 : 8}>
                                <Button variant="contained" type="button" fullWidth color="primary" onClick={handleNext} disabled={selectedHorses[activeStep] === undefined}>
                                    Continue
                                </Button>
                            </Grid>
                        }

                        {(activeStep == 2) &&
                            <Grid item xs={8}>
                                <Button variant="contained" type="button" fullWidth color="primary" onClick={handleNext}>
                                    Confirm and Save
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Paper>
                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title={options[activeStep].title} icon='horse'></PageHeader>
                    </div>

                    <MaterialTable

                        columns={columns[activeStep]}
                        data={data.items}
                        actions={[
                            rowData => ({
                                icon: isAdded(rowData) ? 'check' : 'remove',
                                tooltip: 'Select Horse',
                                onClick: selectHorse
                            })
                        ]}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [1000],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false
                        }}


                    />
                </Paper>
            </Container>
            <div>
                    <Dialog
                        open={isDialogOpen}
                        onClose={(e) => setIsDialogOpen(false)}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                            Confirmation of Transfer
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {selectedHorses[0] !== undefined && selectedHorses[1] !== undefined && 
                                <>
                                    Please Confirm Transfer of '<strong>{selectedHorses[0].horseName}</strong>' for '<strong>{selectedHorses[1].name}</strong>'
                                </>
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" onClick={handleCancel} color="secondary">
                                Cancel
                            </Button>

                            <Button disabled={isLoading} variant="contained" type="button" onClick={handleSubmit} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
        </>
    )
}