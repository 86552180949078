import { history } from '../_helpers/history';
import { accountService } from '../_services/accounts.service';

interface IPrimaryNavInterface {
    icon: string;
    name: string;
    mobileName?: string;
    url: string;
    onClick?: any;
    png?: string;
}

function goTo(link: string) {
    history.push('/' + link);
}

const PRIMARY_NAV: IPrimaryNavInterface[] = [
    { icon: 'home', name: 'Dashboard', mobileName: 'Home', url: 'Dashboard' },
    { icon: 'emoji_events', name: 'Standings', url: 'Standings' },
    { icon: 'compare_arrow', png: 'horse_icon_black', name: 'Horses', url: 'Horses' },
    { icon: 'star_border', name: 'Results', url: 'Results' },
    { icon: 'info', name: 'Rules', url: 'Rules' }
];

const ADMIN_NAV: IPrimaryNavInterface[] = [
    { icon: 'apps', name: 'Competitions', url: 'Competitions' },
    { icon: 'notifications', name: 'Notifications', url: 'Notifications' },
    { icon: 'person_add', name: 'Accounts', url: 'Accounts' },
    { icon: 'cloud_upload', name: 'Uploads', url: 'Uploads' },
    { icon: 'info', name: 'Rules', url: 'Rules' }

];

const USER_NAV: IPrimaryNavInterface[] = [
    { icon: 'exit_to_app', name: 'Log Out', url: 'logout', onClick: accountService.logout }
];

export { PRIMARY_NAV, ADMIN_NAV, USER_NAV };
