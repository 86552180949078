import { API_URL } from '../_config/const';
import { getBody, getHeader } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "userNotificationToken";

export const userNotificationTokenService = {
    subscribe
};

function subscribe(token: string) {
    var model = { token: token };
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/subscribe/`, requestOptions).then(handleResponse);
}

