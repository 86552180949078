import React, { useEffect, useState } from 'react';
import { history } from '../_helpers/history';
import { Button, Icon } from '@material-ui/core';
import { competitionUserService } from '../_services/competitionUser.service';
import { toastr } from '../_components/toastr';
import { useParams } from 'react-router-dom';

export function TransferButton() {
    const [isLoading, setLoading] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');
    const [isCurrentUser, setIsCurrentUser] = useState(true);
    const [transferTokens, setTransferTokens] = useState(0);
    const { userId } = useParams<{ userId: string }>();

    useEffect(() => {
        fetchData();
    }, [userId]);

    function fetchData() {
        setLoading(true);
        const currentUser = JSON.parse(localStorage.getItem('currentUser') ?? "");
        setCurrentUserId(currentUser.id);

        if(userId !== undefined)
            setIsCurrentUser(currentUser.id === userId);

        return competitionUserService.getTransferTokens(currentUser.id)
        .then(
            (res) => {
                setTransferTokens(res);
            },
            (e) => { toastr.error("Something went wrong getting transfer details. Please try again later");})
            .finally(() => setLoading(false));
    }

    function transferHorse() {
        history.push("/Competition/Transfer/" + currentUserId);
    }

    return (
        <>
            {isCurrentUser === true && <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading || transferTokens === 0}
                disableElevation
                onClick={transferHorse}
            >
                Transfer Horse
            </Button>}
        </>
    );
}