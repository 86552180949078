import { Paper } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import horseIcon from '../_images/horse_icon_white.png';
import { GREEN } from '../_theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    icon: {
        fontSize: 32,
        margin: theme.spacing(2),
    },
    iconPaper: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(-3),
        width: theme.spacing(8),
        height: theme.spacing(8),
        backgroundColor: GREEN,
        color: 'white',
    },
    heading: {
        margin: theme.spacing(2),
        color: theme.palette.grey[600],
        alignItems: 'left',
        textAlign: 'left'
    },
    horseIcon: {
        margin: theme.spacing(2),
    }
}),
);

export function PageHeader(props: { title: String, icon?: String, cmp?: Component }) {
    const classes = useStyles();



    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.iconPaper}>

                {(props.icon !== "horse") &&
                    <Icon className={classes.icon}>{props.icon}</Icon>
                }

                {(props.icon === "horse") &&
                    <img width="32" className={classes.horseIcon} src={horseIcon} />
                }

            </Paper>

            <Typography variant="h5" component="h1" className={classes.heading}>
                {props.title}
            </Typography>

            {props.cmp && props.cmp}



        </div >
    );
}