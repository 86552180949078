import { Container, Grid, Paper, TablePagination, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { raceResultService } from '../_services/raceResult.service';
import { fileUploadEntryService } from '../_services/fileUploadEntry.service';
import styles from "../_components/Chart/chartStyle";
import { uploadsService } from '../_services/uploads.service';
import { history } from '../_helpers/history';

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useChartStyles = makeStyles(styles);
const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    marginTop: {
        marginTop: "50px"
    },
    heading: {
        marginTop: "-20px",
        marginBottom: "30px",
        textAlign: "center"

    }
}));

export function UploadRaceResultsView() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [uploadData, setUploadData] = useState<ITableData>({ items: [], count: 0 });
    const [fileUpload, setFileUpload] = useState({ name: "", status: "" });
    const chartClasses = useChartStyles();
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'asc', orderBy: '', uploadId: 0 });

    const classes = useStyles();
    const { uploadId } = useParams<{ uploadId: string }>();

    const uploadedDataColumns = [
        { title: 'Error', field: 'errors' },
        { title: 'Date', field: 'date' },
        { title: 'Course Name', field: 'course_Name' },
        { title: 'Suf', field: 'suf' },
        { title: 'Title', field: 'title' },
        { title: 'Race Type', field: 'race_Type_Desc' },
        { title: 'Race Group', field: 'race_Group_Desc' },
        { title: 'Horse Name', field: 'horse_name' },
        { title: 'Fin Pos', field: 'fin_Pos' },
        { title: 'Trainer', field: 'trainer' },
        { title: 'Odds Value', field: 'odds_Value' },
        { title: 'Prize', field: 'prize' },
        { title: 'Age', field: 'horse_age' },
        { title: 'Dam', field: 'dam' },
        { title: 'YOB', field: 'yob' },
    ];

    const columns = [
        { title: '', field: 'id' },
        { title: 'Race Date', field: 'raceDate', render: (rowData: any) => <>{format(new Date(rowData.raceDate), 'dd/MM/yyyy hh:mm')}</> },
        { title: 'Horse', field: 'horseName', render: (rowData: any) => <div>{(rowData.horseName)}<br></br>({(rowData.parents)})</div> },
        { title: 'Position', field: 'finishPosition' }
    ];

    useEffect(() => {
        fetchData();
        fetchUploadData();
        fetchFileUploadData();
    }, []);

    function fetchUploadData() {
        setLoading(true);
        filter.uploadId = uploadId;
        return fileUploadEntryService.getAll(uploadId)
            .then(json => {
                json.items = json.items || [];
                setUploadData(json);
            })
            .finally(() => setLoading(false));
    }

    function fetchFileUploadData() {
        setLoading(true);
        return uploadsService.get(uploadId)
            .then(json => {
                setFileUpload(json);
            })
            .finally(() => setLoading(false));
    }

    function fetchData() {
        setLoading(true);
        filter.uploadId = uploadId;
        return raceResultService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function deleteResult(event: any, rowData: any) {
        raceResultService
            .removeRaceResult(rowData.id)
            .then(() => { fetchData() })

    }

    function deleteFileUpload() {
        setDisabled(true);
        setLoading(true);
        return uploadsService.remove(uploadId)
            .then(() => { history.push("/Uploads") })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <Container component="main">


                <Grid container className={classes.heading}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h2 className={chartClasses.cardTitle}><strong>{fileUpload.name}: {fileUpload.status}</strong></h2>
                    </Grid>
                </Grid>

                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Uploaded data' icon='terrain'></PageHeader>
                        <Button fullWidth onClick={deleteFileUpload} disabled={isDisabled} variant="contained" color="secondary">
                            {!isDisabled && <>Delete Result File</>}
                            {isDisabled && <>Deleting...</>}
                        </Button>
                    </div>

                    <MaterialTable
                        columns={uploadedDataColumns}
                        data={uploadData.items}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={uploadData.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {uploadedDataColumns && uploadedDataColumns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            initialPage: 0,
                            pageSize: filter.limit,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            maxBodyHeight: 400,
                            emptyRowsWhenPaging: false,

                            rowStyle: rowData => ({
                                backgroundColor: (rowData.errors) ? '#f44336' : '#FFF',
                                color: (rowData.errors) ? 'white' : 'black',
                                "& td": { borderTop: "1px solid red !important" }
                            })
                        }}



                    />
                </Paper>

                <Paper className={classes.marginTop}>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Generated Race Results' icon='cloud_upload'></PageHeader>
                    </div>

                    <MaterialTable
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                onChangeRowsPerPage={(e) => setProp(FilterEnum.limit, e.target.value)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            initialPage: 0,
                            pageSize: filter.limit,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false,
                        }}
                        actions={[{
                            icon: 'clear',
                            tooltip: 'Delete',
                            onClick: deleteResult,
                        }]}

                    />
                </Paper>
            </Container>
        </>
    );
}