import { Button, Icon } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import React, { useEffect, useRef, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { ICompetitionManage } from '../_models';
import { competitionService } from '../_services/competition.service';
import { useDrawerStyles } from '../_styles';
import { getDateString } from '../_helpers/date.helper';
import MenuItem from '@material-ui/core/MenuItem';
import { Prize } from '../_models/prize/prize.model';
import Grid from '@material-ui/core/Grid';

export function CompetitionCreate(params: { successCallback: () => void }) {

    const { competitionId } = useParams<{ competitionId: string }>();
    const [isLoading, setLoading] = useState(false);
    const [competition, updateCompetition] = useState<ICompetitionManage>(
        {
            id: +(competitionId || 0),
            competitionName: '',
            validFrom: '',
            validTo: '',
            rules: "",
            initialPrizePool: 0,
            entryFee: 0,
            defaultReward: 0,
            splitNo2yold: 0,
            splitNoOlder: 0,
            prizePool: []
        });

    const [rules, setRules] = useState("");
    const classes = useDrawerStyles();

    useEffect(() => {
        fetchData()
    }, [competitionId]);

    function fetchData() {
        if (competition.id > 0) {
            return competitionService.get(competition.id)
                .then(data => {
                    data.validFrom = getDateString(new Date(data.validFrom));
                    data.validTo = getDateString(new Date(data.validTo));
                    setRules(data.rules);
                    updateCompetition(data);
                });
        }
    }

    function goTo(link: string) {
        history.push('/' + link);
    }

    function navigateBack() {
        history.push('/competitions');
    };

    function submit(e: any) {
        e.preventDefault();
        setLoading(true);

        let _competition = competition;
        _competition.rules = rules;

        competitionService
            .submit(_competition)
            .then(
                (d) => { console.log(d); params.successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error(e || "Something went wrong. Please try again later") }
            );
    }

    function handleChange(event: any) {
        let _competition = { ...competition };

        if (event.target.name == 'validFrom' || event.target.name == 'validTo')
            _competition[event.target.name] = getDateString(new Date(event.target.value));
        else
            _competition[event.target.name] = event.target.value;

        updateCompetition(_competition)
    };

    function handleRulesChange(content: string) {
        setRules(content)
    }

    function handleAddPrize(event: any) {
        if (!competition.prizePool)
            competition.prizePool = new Array<Prize>();

        const newPrize = { prizePlace: competition.prizePool.length + 1, prizeAmount: 0 } as Prize;

        const _competition = { ...competition, prizePool: [...competition.prizePool, newPrize] };
        updateCompetition(_competition);
    }

    function handleDeletePrize(event: any) {
        // Always deleting from the end
        const _competition = { ...competition };
        _competition.prizePool.pop();
        updateCompetition(_competition);
    }

    function handleChangePrizeRow(event: any, index: number) {
        let _competition = { ...competition };
        _competition.prizePool[index].prizeAmount = event.target.value;
        updateCompetition(_competition)
    }

    function getPrizeRow(prize: Prize, index: number) {
        return <Grid container spacing={0} key={index}>
            <Grid item xs={6} sm={1}>
                <h3><strong>{prize.prizePlace}</strong></h3>
            </Grid>
            <Grid item xs={6} sm={3}>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    type="number"
                    label="Prize Amount"
                    name="prizeAmount"
                    value={prize.prizeAmount}
                    onChange={(e: any) => handleChangePrizeRow(e, index)}
                    disabled={isLoading}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    className={classes.prizeAmount}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <Button
                    fullWidth
                    disabled={isLoading || index + 1 !== competition.prizePool.length}
                    disableElevation
                    onClick={handleDeletePrize}
                >
                    <Icon>delete</Icon>
                </Button>
            </Grid>
        </Grid>
    }

    const editor = useRef(null)
    const [content, setContent] = useState('')

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">

                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">

                        <div className={classes.heading}>
                            <h4>
                                Create Competition

                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>

                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Competition Name"
                                name="competitionName"
                                value={competition.competitionName}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                label="Valid From *"
                                autoComplete="off"
                                onChange={handleChange}
                                type="date"
                                fullWidth
                                name="validFrom"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                value={competition.validFrom}
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                label="Valid To *"
                                autoComplete="off"
                                onChange={handleChange}
                                type="date"
                                fullWidth
                                name="validTo"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                value={competition.validTo}
                            />

                            <SunEditor
                                height={400}
                                setContents={rules}
                                onChange={handleRulesChange}
                            />
                            <div>
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="number"
                                    label="Initial Prize Pool"
                                    name="initialPrizePool"
                                    value={competition.initialPrizePool}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.competitionPrize}
                                />
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="number"
                                    label="Entry Fee Per Person"
                                    name="entryFee"
                                    value={competition.entryFee}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.competitionPrize}
                                />
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="number"
                                    label="Default Reward"
                                    name="defaultReward"
                                    value={competition.defaultReward}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.competitionPrize}
                                />
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="number"
                                    label="No 2YO Racehours"
                                    name="splitNo2yold"
                                    value={competition.splitNo2yold}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.competitionPrize}
                                />
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="number"
                                    label="No Older Racehorses"
                                    name="splitNoOlder"
                                    value={competition.splitNoOlder}
                                    disabled={isLoading}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.competitionPrize}
                                />
                            </div>
                            <div>{(competition.prizePool && competition.prizePool.length > 0) && <h3>Prizes for this competition</h3>}
                                {(competition.prizePool && competition.prizePool.length > 0) &&
                                    competition.prizePool.map((p, index) => getPrizeRow(p, index))}
                            </div>
                            <Button
                                fullWidth
                                disabled={isLoading}
                                disableElevation
                                onClick={handleAddPrize}
                            >
                                <Icon>add</Icon> Add Prize
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                disableElevation
                                className={classes.submit}
                            >
                                <Icon>check</Icon> Save
                            </Button>

                        </ValidatorForm>
                    </div>
                </div>
            </Drawer>
        </>
    );
}


