import { Container, Paper, TablePagination } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { competitionUserService } from '../_services/competitionUser.service';



interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    gold: {
        color: "#FFD700"
    },
    silver: {
        color: "silver"
    },
    bronze: {
        color: "#cd7f32"
    }
}));

export function CompetitionUsers() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 50, page: 0, order: 'desc', orderBy: '', competitionId: 0 });

    const classes = useStyles();

    const { competitionId } = useParams<{ competitionId: string }>();

    const columns = [
        { title: 'Paid', field: 'hasPaid', render: (rowData: any) => <>{rowData.hasPaid ? "PAID" : "UNPAID"}</> },
        { title: 'User', field: 'email' },
        { title: 'Department', field: 'department' },
    ];

    useEffect(() => { fetchData() }, []);

    function fetchData() {
        setLoading(true);
        filter.competitionId = competitionId;

        return competitionUserService.getAll(filter)
            .then(json => {
                json.items = json.items || [];
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function approvePayment(e: any, data: any) {
        competitionUserService.approve(data.id).then(
            () => { toastr.success("User approved successfully"); fetchData(); },
            (err) => { console.log(err); toastr.error("Something went wrong. Please try again later"); }
        );
    }

    return (
        <>
            <Container component="main">


                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Competition Users' icon='verified_user'></PageHeader>
                    </div>

                    <MaterialTable
                        columns={columns}
                        data={data.items}
                        actions={[
                            rowData => ({
                                icon: !rowData.hasPaid ? 'check' : '',
                                tooltip: 'Approve Payment',
                                onClick: approvePayment,
                            })
                        ]}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >
                                            Action
                                        </TableCell>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false
                        }}


                    />
                </Paper>
            </Container>
        </>
    );
}