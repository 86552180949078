import { Grid, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import MaterialTable from 'material-table';
import { default as React, useEffect, useState } from 'react';
import ChartistGraph from "react-chartist";
import { useParams } from 'react-router-dom';
import Card from "../_components/Card/Card.js";
import CardBody from "../_components/Card/CardBody.js";
import CardFooter from "../_components/Card/CardFooter.js";
import CardHeader from "../_components/Card/CardHeader.js";
import { dailySalesChart } from "../_components/Chart/chartData.js";
import styles from "../_components/Chart/chartStyle";
import { toastr } from '../_components/toastr.js';
import { history } from '../_helpers/history';
import { competitionScoreService } from '../_services/competitionScore.service';
import { Console } from 'console';


const useChartStyles = makeStyles(styles);
interface ITableData {
    items: Array<any>;
    count: number;
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    button: {
        margin: theme.spacing(1),
        flex: 1,
        width: "300px"
    },
    gold: {
        color: "#FFD700"
    },
    silver: {
        color: "silver"
    },
    bronze: {
        color: "#cd7f32"
    }
}));

export function DashboardImidiateCompetition() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);
    const [userPositionProgress, setUserPositionProgress] = useState({ labels: [], series: [[]] });

    const [stateChange, setStateChange] = useState("a");
    const [positionsSinceLastUpload, setPositionsSinceLastUpload] = useState(0);

    const { userId } = useParams<{ userId: string }>();

    function fetchData() {
        setLoading(true);
        return competitionScoreService.getAllImidiate((userId || ""))
            .then(
                (json) => {
                    setData(json)
                },
                (e) => console.log("re2"))
            .finally(() => setLoading(false));
    }

    function fetchImidiateGraphData() {
        return competitionScoreService.getGraphData((userId || ""))
            .then(
                (json) => {

                    let _userPositionProgress = userPositionProgress;
                    _userPositionProgress.labels = json.map((m: any) => m.key);

                    _userPositionProgress.series = [json.map((m: any) => (m.userPoints ? m.userPoints.position : m.totalUsers) * -1)];
                    setUserPositionProgress(_userPositionProgress)

                    if (_userPositionProgress.series && _userPositionProgress.series[0] && _userPositionProgress.series[0].length >= 2) {
                        var _l = _userPositionProgress.series[0].length;
                        setPositionsSinceLastUpload((_userPositionProgress.series[0][_l - 1] - _userPositionProgress.series[0][(_l - 2)]));
                    }
                    console.log(userPositionProgress);
                    setStateChange("sate change");
                },
                (e) => { toastr.error("Unable to load graph data"); console.log("re2"); })
            .finally(() => setLoading(false));
    }

    const columns = [
        { title: 'Position', field: 'position', render: (rowData: any) => rowData.position < 4 ? <><Icon className={(rowData.position == 1) ? classes.gold : (rowData.position == 2) ? classes.silver : classes.bronze}>emoji_events</Icon></> : <>{rowData.position}{rowData.positionString}</> },
        { title: 'Points', field: 'points' },
        { title: 'User', field: 'email' },
        { title: 'Department', field: 'department' },
    ];

    useEffect(() => {
        fetchData();
        fetchImidiateGraphData();
    }, [userId]);

    const classes = useStyles();
    const chartClasses = useChartStyles();

    function goToUser(userId: string) {
        history.push("/User/" + userId)
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Card chart>
                    {/*
                    <CardHeader color="success">


                        <ChartistGraph
                            className="ct-chart"
                            data={userPositionProgress}
                            type="Line"
                            options={{ ...dailySalesChart.options, }}
                        />


                    </CardHeader>
                    */}
                    <CardBody>
                        <h4 className={chartClasses.cardTitle}>Standings</h4>
                        <p className={chartClasses.cardCategory}>
                            {/*
                            {(positionsSinceLastUpload > 0) &&
                                <span className={chartClasses.successText}>
                                    <ArrowUpward className={chartClasses.upArrowCardCategory} />{positionsSinceLastUpload}
                                </span>
                            }

                            {(positionsSinceLastUpload < 0) &&
                                <span className={chartClasses.errorText}>
                                    <ArrowDownward className={chartClasses.upArrowCardCategory} />{positionsSinceLastUpload}
                                </span>
                            }

                            {(positionsSinceLastUpload == 0) &&
                                <>-</>
                            }

                            {" "} positions since last upload
                        */}
                        </p>
                    </CardBody>
                    <CardFooter chart >
                        <div className={chartClasses.stats}>
                        </div>
                    </CardFooter>
                    <MaterialTable
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onRowClick={(e: any, data: any) => goToUser(data.userId)}
                        components={{
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}>
                                                {h.title}
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}

                        options={{
                            search: false,
                            toolbar: false,
                            paging: false,
                            showEmptyDataSourceMessage: true,
                            sorting: false,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                        }}


                    />
                </Card>
            </Grid>

        </Grid>
    );
}

