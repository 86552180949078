import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';

const API_NAME = "dropdown";

export const dropdownService = {
    getDams,
    getSires,
    getGenders,
    getHorseCategories
};

function getDams() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetDams/`, requestOptions).then(handleResponse);
}

function getSires() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetSires/`, requestOptions).then(handleResponse);
}

function getGenders() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetGenders/`, requestOptions).then(handleResponse);
}

function getHorseCategories() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetHorseCategories/`, requestOptions).then(handleResponse);
}