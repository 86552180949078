import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { FormEvent, useEffect, useState } from 'react';
import 'typeface-roboto';
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { PageHeader } from '../common/pageHeader';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { accountService } from '../_services/accounts.service';
import { toastr } from '../_components/toastr';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { history } from '../_helpers/history';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    backToLogin: {
        paddingTop: "20px"
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#282f5b'
        },
    }
});

export function RegisterIndex() {
    const [isLoading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({ firstName: '', secondName: '', email: '', department: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const classes = useStyles();

    function submit() {
        setLoading(true);
        accountService.register(userDetails)
        .then(
            (item => { 
                setIsDialogOpen(true);
            }),
            ((err) => {
                toastr.error("Something went wrong. Please try again later");
                setIsDialogOpen(false);
            }))
        .finally(() => { setLoading(false); });
    }
    
    function handleChange(event: any) {
        var _userDetails = userDetails;
        (_userDetails as any)[event.target.name] = event.target.value;
        setUserDetails({ ..._userDetails });
    };

    function closeDialog() {
        setIsDialogOpen(false);
        goTo('login');
    }

    function goTo(link: string) {
        history.push('/'+link);
    }
    
    return (
        <div className={classes.paper}>
            <div className={classes.header}>
                <PageHeader title='Register' icon='lock'></PageHeader>
            </div>
            <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    value={userDetails.email}
                    disabled={isLoading}
                    onChange={handleChange}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Email is not valid']}
                    autoFocus
                    autoComplete='off'
                />
                <Grid container spacing={0}>
                    <Grid container item spacing={0} xs={6}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="firstName"
                            label="First Name"
                            id="firstName"
                            disabled={isLoading}
                            value={userDetails.firstName}
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            autoComplete='off'
                        />
                    </Grid>
                    <Grid container item spacing={0} xs={6}>
                        <TextValidator
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="secondName"
                            label="Second Name"
                            id="secondName"
                            disabled={isLoading}
                            value={userDetails.secondName}
                            onChange={handleChange}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            autoComplete='off'
                        />
                        </Grid>
                </Grid>
                <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Department"
                                name="department"
                                value={userDetails.department}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoComplete='off'
                            />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    disableElevation
                    className={classes.submit}
                >
                    Register
                </Button>
            </ValidatorForm>

            <div>
                <Dialog
                    open={isDialogOpen}
                    onClose={(e) => setIsDialogOpen(false)}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Registration Confirmation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <strong>Your registration details have been sent to an admin for approval.<br />
                            You will get a confirmation email with a link to set a password once approved.
                            </strong>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={closeDialog} color="secondary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <Link onClick={() => goTo("login")} className={classes.backToLogin}>
                Back to Log In screen
            </Link>
        </div>
        
    );
}