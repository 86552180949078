import { Icon, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from "../_components/Card/Card.js";
import CardFooter from "../_components/Card/CardFooter.js";
import CardHeader from "../_components/Card/CardHeader.js";
import CardIcon from "../_components/Card/CardIcon.js";
import styles from "../_components/Chart/chartStyle";
import GridContainer from "../_components/Grid/GridContainer.js";
import GridItem from '../_components/Grid/GridItem';
import Danger from "../_components/Typography/Danger.js";
import { competitionHorseScoringService } from "../_services/competitionHorseScoring.service";

const useStyles = makeStyles(styles);

export function HorseChartPosition() {
    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const { horseId } = useParams();

    useEffect(() => {
        fetchData();
    }, [horseId]);

    function fetchData() {
        setLoading(true);
        return competitionHorseScoringService.getPosition((horseId || ""))
            .then(json => {
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>grade</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Rankings</p>
                            <h3 className={classes.cardTitle}>

                                {(!isLoading && data) &&
                                    <>
                                        {data.position}<small>{data.positionString}</small>/{data.totalParticipants}
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <Icon>table</Icon>
                                </Danger>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    View Table
                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>store</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Total Points</p>
                            <h3 className={classes.cardTitle}>
                                {(!isLoading && data) &&
                                    <>
                                        {data.totalPoints} pts
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Icon>date_range</Icon>
                                {(!isLoading && data) &&
                                    <>
                                        {data.averagePoints}
                                    </>
                                }

                                {(isLoading) &&
                                    '-'
                                }
                                <>&nbsp;</>average per upload
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>date_range</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Last Upload Points</p>
                            <h3 className={classes.cardTitle}>
                                {(!isLoading && data) &&
                                    <>
                                        {data.pointsInLastUpload}
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Icon>timer</Icon>
                                Last 24 Hours
              </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
