
import { makeStyles } from '@material-ui/core/styles';

export const useDrawerStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-4),
        marginRight: theme.spacing(-1),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    closeButton: {
        float: "right",
        paddingTopt: theme.spacing(1)
    },
    drawer: {
        padding: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    gridItem: {
        padding: "0px !important"
    },
    competitionPrize: {
        padding: "5px !important"
    },
    prizeAmount: {
        
    }
}));