import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { accountService } from '../_services/accounts.service';


function Alert(props: AlertProps) {
    return <MuiAlert elevation={1} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    forgotPassword: {
        paddingTop: "20px"
    }
}));

export function LoginIndex() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const classes = useStyles();

    function submit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        setErrorMsg("");

        accountService.login(username, password, rememberMe)
            .then(
                (item => {

                }),
                ((err) => {
                    console.log(err);
                    if (err == 'Username or password is incorrect') {
                        setErrorMsg(err);
                    }
                    else {
                        toastr.error("Something went wrong. Please try again later");
                    }
                }))
            .finally(() => { setLoading(false); });
    }

    function register() {
        history.push('/login/register');
    }

    function goTo(link: string) {
        history.push('/' + link);
    }

    if (accountService.currentUserValue) {
        history.push('/');
    }

    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    return (
        <div className={classes.paper}>
            <div className={classes.header}>
                <PageHeader title='Sign In' icon='lock'></PageHeader>
            </div>

            {errorMsg &&
                <Alert severity="error">{errorMsg}!</Alert>
            }

            <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    value={username}
                    disabled={isLoading}
                    onChange={(e: any) => setUsername(e.target.value.toString())}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Email is not valid']}
                    autoFocus
                />


                <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    disabled={isLoading}
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value.toString())}
                    validators={['required']}
                    errorMessages={['This field is required']}
                    autoComplete="current-password"
                />

                <FormControlLabel
                    disabled={isLoading}
                    value={rememberMe}
                    onChange={e => setRememberMe(!rememberMe)}
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                <Grid container spacing={0}>
                    <Grid container item spacing={0} xs={8}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            disableElevation
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                    </Grid>
                    <Grid container item spacing={0} xs={4}>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            disabled={isLoading}
                            disableElevation
                            className={classes.submit}
                            onClick={register}
                        >
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>

            <Link onClick={() => goTo("login/forgotpassword")} className={classes.forgotPassword}>
                Forgot Password
            </Link>
        </div>
    );
}