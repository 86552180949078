import { AppBar, Box, Hidden, Icon, Toolbar, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { history } from '../_helpers/history';
import coolmoreLogo from '../_images/Coolmore_white.png';
import { accountService } from '../_services/accounts.service';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        appBar: {
            flexGrow: 1,
        },
        coolmoreLogo: {
            width: "calc(240px * 0.95)",
            marginTop: "0px",
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    })
);

export function AppToolbar() {

    const classes = useStyles();

    function logOut() {
        accountService.logout();
        window.location.reload();
    }

    function goTo(link: string) {
        history.push('/' + link);
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>

            <Toolbar>


                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                </IconButton>

                <Typography variant="h6" className={classes.title}>
                    <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
                        <img className={classes.coolmoreLogo} src={coolmoreLogo}></img>
                    </Box>
                </Typography>

                <Hidden mdUp>
                    <IconButton edge="start" onClick={accountService.logout} className={classes.menuButton} color="inherit" aria-label="menu">
                        <Icon>exit_to_app</Icon>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}