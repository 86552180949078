import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import 'typeface-roboto';
import { AuthRoute } from '../authRoute/authRoute';
import { history } from '../_helpers/history';
import { AppScreen } from '../_screens/app-screen';
import { LoginScreen } from '../_screens/login-screen';
import './App.css';


const App: React.FC = () => {

  return (
    <span>
      <Router history={history}>
        <Route path='/'>
          <Switch>
            <Route path='/login' component={LoginScreen} />
            <AuthRoute path='/' component={AppScreen} />
          </Switch>
        </Route>

      </Router>
    </span>
  );
}

export default App;
