import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { getBody } from '../_helpers/body-helper';

const API_NAME = "competitionscore";

export const competitionScoreService = {
    getAll,
    getAllImidiate,
    getGraphData
};

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getAllImidiate(userId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAllImidiate/?userId=${userId}`, requestOptions).then(handleResponse);
}


function getGraphData(userId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetGraphData/?userId=${userId}`, requestOptions).then(handleResponse);
}

