import { BehaviorSubject } from 'rxjs';

import { AUTH_URL } from '../_config/const';
import { getBody } from '../_helpers/body-helper';
import { handleResponse } from '../_helpers/handle-response';
import { getHeader } from '../_helpers/header.helper';
import { getQuery, history } from '../_helpers';
import { requestForToken } from '../_messaging/firebase/firebase-sw';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser') ? (JSON.parse(localStorage.getItem('currentUser') ?? "")) : null);

export const accountService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    getAll,
    get,
    post,
    put,
    disable,
    hasPaid,
    getUserName,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
    get isAdmin() { return (currentUserSubject.value && currentUserSubject.value.roles.length && currentUserSubject.value.roles.some((a: any) => a === "Admin")) },
    register,
    confirmAccount,
    unsubscribeFromNotificationEmails
};

function login(email: string, password: string, rememberMe: boolean) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, rememberMe })
    };

    return fetch(`${AUTH_URL}/account/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            requestForToken();

            return user;
        });
}

function setCompetitionId(competitionId: any) {
    localStorage.setItem('ongoingCompetitionId', JSON.stringify(competitionId));
    currentUserSubject.next(null);
}

function hasPaid() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/hasPaid/`, requestOptions).then(handleResponse);
}

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/getAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getUserName(id: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/getUserName/?userId=${id}`, requestOptions).then(handleResponse);
}

function get(id: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/get/?userId=${id}`, requestOptions).then(handleResponse);
}

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/create`, requestOptions).then(handleResponse);
}

function put(accountId: any, model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    console.log("RE", accountId, model);
    return fetch(`${AUTH_URL}/account/update/?userId=${accountId}`, requestOptions).then(handleResponse);
}


function disable(accountId: any) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/disable/?userId=${accountId}`, requestOptions).then(handleResponse);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    history.push("/");
}

function forgotPassword(email: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(`${AUTH_URL}/account/forgotpassword`, requestOptions)
        .then(handleResponse);
}

function resetPassword(email: string, password: string, confirmPassword: string, code: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, confirmPassword, code })
    };

    return fetch(`${AUTH_URL}/account/resetpassword`, requestOptions)
        .then(handleResponse);
}

function register(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${AUTH_URL}/account/register`, requestOptions).then(handleResponse);
}

function confirmAccount(accountId: any) {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${AUTH_URL}/account/confirmAccount/?userId=${accountId}`, requestOptions).then(handleResponse);
}

function unsubscribeFromNotificationEmails(email: string, token: string) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token })
    };

    return fetch(`${AUTH_URL}/account/UnsubscribeFromNotificationEmails`, requestOptions).then(handleResponse);
}