export function getQuery(query: any): string {

  var arr = [];
  for (var property in query) {
    if (query.hasOwnProperty(property)) {
      if (query[property] !== 'null' && query[property] !== 'undefined')
        arr.push(property + "=" + query[property]);
    }
  }

  return arr.join("&");
}