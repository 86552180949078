import { Icon, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from "../_components/Card/Card.js";
import CardFooter from "../_components/Card/CardFooter.js";
import CardHeader from "../_components/Card/CardHeader.js";
import CardIcon from "../_components/Card/CardIcon.js";
import styles from "../_components/Chart/chartStyle";
import GridContainer from "../_components/Grid/GridContainer.js";
import GridItem from '../_components/Grid/GridItem';
import Danger from "../_components/Typography/Danger.js";
import { history } from '../_helpers/history';
import { competitionUserScoringService } from "../_services/competitionUserScoring.service";

const useStyles = makeStyles(styles);

export function ChartPosition() {
    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const { userId } = useParams();

    useEffect(() => {
        fetchData();
    }, [userId]);

    function fetchData() {
        setLoading(true);
        return competitionUserScoringService.getPosition((userId || ""))
            .then(json => {
                setData(json);
            })
            .finally(() => setLoading(false));
    }

    function goToStandings() {
        history.push("/Standings");
    }

    function getAveragePoints() {
        return parseFloat((data && data.lastUpload ? data.totalPoints / data.totalUploads : 0), 0).toFixed(2).toString();
    }

    function getLastUploadPoints() {
        return parseFloat((data && data.lastUpload ? data.lastUpload.totalPoints : 0), 0).toString();
    }

    function getLastUploadDateString() {
        return data && data.lastUpload ? data.lastUpload.lastUploadString : "";
    }

    function isLastUploadAvailable() {
        return data && data.lastUpload && data.lastUpload.lastUploadString;
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <Icon>grade</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Position</p>
                            <h3 className={classes.cardTitle}>

                                {(!isLoading && data) &&
                                    <>
                                        {data.position}<small>{data.positionString}</small>/{data.totalParticipants}
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <Icon>table</Icon>
                                </Danger>
                                <a onClick={e => goToStandings()}>
                                    View Table
                </a>
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <Icon>store</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Total Points</p>
                            <h3 className={classes.cardTitle}>
                                {(!isLoading && data) &&
                                    <>
                                        {data.totalPoints} pts
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Icon>date_range</Icon>
                                {(!isLoading && data) &&
                                    <>
                                        {getAveragePoints()}
                                    </>
                                }

                                {(isLoading) &&
                                    '-'
                                }
                                <>&nbsp;</>average per upload
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <Card>
                        <CardHeader color="primary" stats icon>
                            <CardIcon color="primary">
                                <Icon>date_range</Icon>
                            </CardIcon>
                            <p className={classes.cardCategory}>Last Upload Points</p>
                            <h3 className={classes.cardTitle}>
                                {(!isLoading && data) &&
                                    <>
                                        {getLastUploadPoints()}
                                    </>
                                }

                                {(isLoading) &&
                                    <LinearProgress />
                                }
                            </h3>
                        </CardHeader>
                        <CardFooter stats>
                            <div className={classes.stats}>
                                <Icon>timer</Icon>
                                {isLastUploadAvailable() &&
                                    <>Last upload {getLastUploadDateString()} ago</>}
                                {!isLastUploadAvailable() &&
                                    <>Not data available</>}
                            </div>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
}
