import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route } from 'react-router-dom';
import 'typeface-roboto';
import { Footer } from '../common/footer';
import { LoginForgotPassword } from '../login/login-forgotPassword';
import { LoginIndex } from '../login/login-index';
import { LoginResetPassword } from '../login/login-resetPassword';
import { EmailUnsubscribe } from '../login/login-emailUnsubscribe';
import coolmoreLogo from '../_images/Coolmore_white.png';
import backgroundImg from '../_images/dark-tire.png';
import { RegisterIndex } from '../register/register-index';

const useStyles = makeStyles(theme => ({

    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: backgroundImg,
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    mainBackground: {
        paddingTop: "50px",
    },
    ieBox: {
        maxWidth: "600px",
        margin: "0px auto"
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#282f5b'
        },
    }
});

export function LoginScreen() {

    const classes = useStyles();

    return (
        <div className={classes.appScreen} id="app-screen">
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="sm" className={classes.mainBackground}>
                    <div className={classes.ieBox}>
                        <img className={classes.coolmoreLogo} src={coolmoreLogo}></img>

                        <Paper elevation={3}>

                            <CssBaseline />

                            <Route exact path="/login" component={LoginIndex} />
                            <Route exact path="/login/forgotpassword" component={LoginForgotPassword} />
                            <Route path="/login/resetpassword/:newUser/:code+" component={LoginResetPassword} />
                            <Route path="/login/register" component={RegisterIndex} />
                            <Route path="/login/emailUnsubscribe/:email/:token" component={EmailUnsubscribe} />

                            <Box mt={8}>
                                <Footer />
                            </Box>
                        </Paper>
                    </div>
                </Container>
            </ThemeProvider>
        </div>
    );
}