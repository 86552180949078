import { makeStyles, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../common/pageHeader';
import { history } from '../_helpers/history';
import Alert from '@material-ui/lab/Alert';
import { competitionService } from '../_services/competition.service';

interface ITableData {
    items: Array<any>;
    count: number;
}


const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee",
        width: "100%"
    },
    button: {
        backgroundColor: '#28a745',
        borderColor: '#28a745',
        '&:hover': {
            backgroundColor: '#218838',
            borderColor: '#218838',
        },
        fontSize: "24px"
    },
    paper: {
        marginBottom: "40px"
    }
}));

export function DashboardOngoingCompetitions() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);

    function fetchData() {
        setLoading(true);
        return competitionService.getAllOngoingCompetitions()
            .then(json => {
                setData(json);
                setLoading(false);
            })
            .finally();
    }

    const columns = [
        { title: 'Competition Name', field: 'competitionName' }
    ];

    useEffect(() => { fetchData() }, []);
    const classes = useStyles();

    function goToJoin(id: number) {
        history.push('/Competition/' + id + '/Join/');
    }

    return (
        <>
            {(data && data.items) && data.items.map(comp =>
                <Paper className={classes.paper}>
                    <CssBaseline />
                    <div className={classes.pageHeader}>
                        <PageHeader title={`Competition Available! Join ${comp.competitionName} for a chance to win a share of €${comp.initialPrizePool}`} icon='compare_arrow'></PageHeader>

                    </div>
                    <Button fullWidth className={classes.button} onClick={(e: any) => goToJoin(comp.id)} variant="contained" color="primary">Join Competition</Button>
                </Paper>
            )}

            {((!data || !data.items || data.items.length === 0) && isLoading === false) &&
                <Alert severity="info"><strong>No competitions available!</strong></Alert>
            }

        </>
    );
}

