import { Container, Paper, TablePagination, Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { accountService } from '../_services/accounts.service';
import { AccountCreate } from './account-create';
import { AccountEdit } from './account-edit';
import { reportService } from '../_services/report.service';
import { API_URL } from '../_config/const';
import Checkbox from '@material-ui/core/Checkbox';

interface ITableData {
    items: Array<any>;
    count: number;
}

interface IFilteringViewModel {
    search: string;
    limit: number;
    page: number;
    order?: 'asc' | 'desc';
    orderBy: string;
    [key: string]: any;
}

enum FilterEnum {
    search = 'search',
    limit = 'limit',
    page = 'page',
    order = 'order',
    orderBy = 'orderBy'
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    }
}));

export function AccountIndex() {
    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);
    const [filter, setFilter] = useState<IFilteringViewModel>({ search: '', limit: 100, page: 0, order: 'desc', orderBy: '' });
    const classes = useStyles();

    const columns = [
        { title: 'Email', field: 'email' },
        { title: 'Name', field: 'name' },
        { title: 'Department', field: 'department' },
        { title: 'Roles', field: 'roles' },
        { title: 'Status', field: 'isActive', render: (rowData: any) => <>{rowData.isActive ? "Active" : "Inactive"}</> },
        { title: 'Registration Confirmed', field: 'registrationConfirmed', render: (rowData: any) => <>{rowData.registrationConfirmed ? "Yes" : "No"}</> },
    ];

    useEffect(() => { fetchData() }, []);

    type updateDataProp = (n: any) => void;

    function fetchData() {
        setLoading(true);
        return accountService.getAll(filter)
            .then(data => {
                data.items = data.items || [];
                console.log(data.items);
                setData(data);
            })
            .finally(() => setLoading(false));
    }

    function setProp(propName: FilterEnum, propValue: any) {
        const query = filter;
        query[propName.toString()] = propValue;
        setFilter({ ...query });
        fetchData();
    }

    function handleRequestSort(property: string) {
        const query = filter;
        query.orderBy = property;
        let order: 'asc' | 'desc' = 'desc';

        if (filter.orderBy === property && filter.order === 'desc') {
            order = 'asc';
        }

        query.order = order;

        setFilter({ ...query });
        fetchData();
    }

    function createUser() {
        history.push("/Accounts/Create")
    }

    function updateUser(accountId: any) {
        history.push(`/Accounts/Edit/${accountId}`)
    }

    function createUserPostBack() {
        toastr.success("User created successfully");
        fetchData();
    }

    function updateUserPostBack() {
        toastr.success("User updated successfully");
        fetchData();
    }

    function downloadReport() {
        setDisabled(true);
        setLoading(true);
        return reportService.getReportToken()
            .then(json => {
                window.location.href = `${API_URL}/report/GetTotalBreakDownReport/?token=${json}`;
            })
            .finally(() => setLoading(false));
    }

    return (
        <>

            <Container component="main">
                <Paper>
                    <CssBaseline />

                    <div className={classes.pageHeader}>
                        <PageHeader title='Users' icon='list_alt'></PageHeader>
                        <Button fullWidth onClick={downloadReport} disabled={isDisabled} variant="contained" color="primary">
                            {!isDisabled && <>Print Total Breakdown Report</>}
                            {isDisabled && <>Downloading...</>}
                        </Button>
                    </div>

                    <MaterialTable
                        onRowClick={(e: any, data: any) => updateUser(data.id)}
                        columns={columns}
                        data={data.items}
                        isLoading={isLoading}
                        onSearchChange={(propValue) => setProp(FilterEnum.search, propValue)}
                        onChangeRowsPerPage={(size: any) => setProp(FilterEnum.limit, size)}
                        components={{
                            Pagination: (componentProps) => <TablePagination
                                {...componentProps}
                                count={data.count}
                                onChangePage={(e, propValue) => setProp(FilterEnum.page, propValue)}
                                page={filter.page}
                                rowsPerPage={filter.limit}
                            />,
                            Header: d =>
                                <TableHead>
                                    <TableRow>
                                        {columns && columns.map(h =>
                                            <TableCell key={h.title}
                                                sortDirection={filter.orderBy === h.field ? filter.order : false}>
                                                <Tooltip
                                                    title="Sort"
                                                    enterDelay={300}>
                                                    <TableSortLabel
                                                        active={filter.orderBy === h.field}
                                                        direction={filter.order}
                                                        onClick={e => handleRequestSort(h.field)}>
                                                        {h.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>

                                </TableHead>
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                onClick: (event) => createUser()
                            }
                        ]}
                        options={{
                            search: true,
                            toolbar: true,
                            pageSize: filter.limit,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                            initialPage: 0,
                            showEmptyDataSourceMessage: true,
                            sorting: true,
                            showTitle: false,
                            loadingType: 'linear',
                            debounceInterval: 200,
                            emptyRowsWhenPaging: false,
                            rowStyle: rowData => ({
                                color: (rowData.registrationConfirmed === false) ? "red" : "black"
                              })

                        }}


                    />
                </Paper>
            </Container>

            <Route path="/Accounts/Create" component={() => <AccountCreate successCallback={createUserPostBack}></AccountCreate>} />
            <Route path="/Accounts/Edit/:accountId" component={() => <AccountEdit successCallback={updateUserPostBack}></AccountEdit>} />
        </>
    );
}