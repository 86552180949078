import { Container } from '@material-ui/core';
import React from 'react';
import { Rules } from './rules';

export function RulesIndex() {

    return (
        <>
            <Container component="main">
                <Rules></Rules>
            </Container>
        </>
    );
}