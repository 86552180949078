import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { getBody } from '../_helpers/body-helper';

const API_NAME = "CompetitionHorseScoring";

export const competitionHorseScoringService = {
    getPosition,
    get,
    getAll,
    getHorseGraphDataPerUserUpload
};

function getPosition(horseId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetPosition/?horseId=${horseId}`, requestOptions).then(handleResponse);
}

function get(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/Get/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetAll/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function getHorseGraphDataPerUserUpload(horseId: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetHorseGraphDataPerUserUpload/?horseId=${horseId}`, requestOptions).then(handleResponse);
}
