import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { getBody } from '../_helpers/body-helper';

const API_NAME = "CompetitionUserScoring";

export const competitionUserScoringService = {
    getPosition
};

function getPosition(userId?: string) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetPosition/?userId=${userId}`, requestOptions).then(handleResponse);
}

