import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { default as React, useEffect, useState } from 'react';
import Card from "../_components/Card/Card.js";
import CardBody from "../_components/Card/CardBody.js";
import CardFooter from "../_components/Card/CardFooter.js";
import styles from "../_components/Chart/chartStyle";
import { competitionUserService } from '../_services/competitionUser.service';

const useChartStyles = makeStyles(styles);

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    gold: {
        color: "#FFD700"
    },
    silver: {
        color: "silver"
    },
    bronze: {
        color: "#cd7f32"
    }
}));

export function Rules() {
    const classes = useStyles();
    const chartClasses = useChartStyles();

    const [isLoading, setLoading] = useState(true);
    const [competitionRules, setCompetitionRules] = useState(null);

    function fetchData() {
        return competitionUserService.getRules()
            .then(json => {
                setCompetitionRules(json);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>

            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card chart>
                        <CardBody>
                            <h4 className={chartClasses.cardTitle}>Rules</h4>
                        </CardBody>
                        <CardFooter chart >
                            <div className={chartClasses.stats}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: (competitionRules||"")
                                    }}></div>
                            </div>
                        </CardFooter>

                    </Card>
                </Grid>

            </Grid>
        </>
    );
}