import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { FormEvent, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useLocation, useParams } from 'react-router-dom';
import { PageHeader } from '../common/pageHeader';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { accountService } from '../_services/accounts.service';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    header: {
        marginLeft: theme.spacing(-8),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    backToLogin: {
        paddingTop: "20px"
    }
}));

interface ParamTypes {
    email: string;
    token: string;
  }

export function EmailUnsubscribe(props: any) {
    const [isLoading, setLoading] = useState(false);
    const {email , token } = useParams<ParamTypes>();
    const strippedToken = token && token.indexOf("token=") !== -1 ? token.split("token=")[1] : "";
    const strippedEmail = email && email.indexOf("email=") !== -1 ? email.split("email=")[1] : "";

    const classes = useStyles();
    
    function submit(e: FormEvent) {
        setLoading(true);

        accountService.unsubscribeFromNotificationEmails(strippedEmail, strippedToken)
            .then(
                (item => {
                    toastr.success("Unsubscribed from notification emails successfully");
                    history.push('/');
                }),
                ((err) => {
                    toastr.error(err.Message || err.title || err || "Something went wrong. Please try again later");
                }))
            .finally(() => { setLoading(false); });
    }

    function goTo(link: string) {
        history.push('/' + link);
    }

    return (
        <div className={classes.paper}>
            <div className={classes.header}>
                <PageHeader title='Email Unsubscribe' icon='lock'></PageHeader>
            </div>

            <ValidatorForm className={classes.form} onSubmit={submit} noValidate>

                <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    value={strippedEmail}
                    disabled={true}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Email is not valid']}
                    autoFocus
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    disableElevation
                    className={classes.submit}
                >
                    Unsubscribe
                </Button>

            </ValidatorForm>

            <Link onClick={() => goTo("login")} className={classes.backToLogin}>
                Back to Log In screen
            </Link>

        </div>
    );
}

