import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CollectionsBookmarkRounded } from '@material-ui/icons';
import { format } from 'date-fns';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import ChartistGraph from "react-chartist";
import { useParams } from 'react-router-dom';
import Card from "../_components/Card/Card.js";
import CardBody from "../_components/Card/CardBody.js";
import CardFooter from "../_components/Card/CardFooter.js";
import CardHeader from "../_components/Card/CardHeader.js";
import { emailsSubscriptionChart } from "../_components/Chart/chartData.js";
import styles from "../_components/Chart/chartStyle";
import { history } from '../_helpers/history';
import { competitionUserHorseScoreService } from '../_services/competitionUserHorseScore.service';
const useChartStyles = makeStyles(styles);

interface ITableData {
    items: Array<any>;
    count: number;
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        borderBottom: "1px solid #eee"
    },
    button: {
        margin: theme.spacing(1),
        flex: 1,
        width: "300px"
    }
}));

export function DashboardHorseResults() {

    const [data, setData] = useState<ITableData>({ items: [], count: 0 });
    const [isLoading, setLoading] = useState(false);

    const [userHorsePointsPerUpload, setUserHorsePointsPerUpload] = useState({ labels: [], series: [[]] });
    const [userHorsePointsPerUploadOptions, setUserHorsePointsPerUploadOptions] = useState(emailsSubscriptionChart.options);
    const [horsePointsPerUpload, setHorsePointsPerUpload] = useState({ labels: [], series: [[]] });

    const { userId } = useParams<{ userId: string }>();

    function fetchData() {
        setLoading(true);
        return competitionUserHorseScoreService.getAll((userId || ""))
            .then(
                (json) => {
                    var _newItems = data;
                    _newItems.items = json;
                    setData(_newItems)
                },
                (e) => console.log("re2"))
            .finally(() => setLoading(false));
    }

    function fetchHorseGraphData() {
        return competitionUserHorseScoreService.getHorseGraphDataPerUserUpload((userId || ""))
            .then(
                (json) => {
                    let _userHorsePointsPerUpload = userHorsePointsPerUpload;
                    let _setUserHorsePointsPerUploadOptions = setUserHorsePointsPerUploadOptions;

                    _userHorsePointsPerUpload.labels = json.map((m: any) => m.label);
                    _userHorsePointsPerUpload.series = [json.map((m: any) => m.totalPoints)];

                    setUserHorsePointsPerUpload(_userHorsePointsPerUpload);
                },
                (e) => console.log("re2"));
    }

    const columns = [
        { 
            title: 'Name',
            field: 'horseName', 
			render: (rowData: any) => {


                return rowData.transferDate !== null ? 
                        rowData.isActive === false ? 
                            <>
                                <span style={{ textDecoration: "line-through" }}> {(rowData.horseName)}</span>
                                <span style={{ color: "red" }}>(Transfer out)</span>
                                <span style={{ textDecoration: "line-through" }}> <br></br>({(rowData.parents)})</span> 
                            </>  
                        :
                        <>
                        <span> {(rowData.horseName)}</span>
                        <span style={{ color: "#1cc930" }}>(Transfer in)</span>
                        <span> <br></br>({(rowData.parents)})</span> 
                    </> 
                : 
                <span>{(rowData.horseName)}<br></br>({(rowData.parents)})</span>
            }
        },
        { title: 'Total', field: 'totalPoints' },
        { title: 'Latest', field: 'latestUploadPoints' },
        { title: 'Category', field: 'categoryName' },
        { title: 'Sex', field: 'sex' },
        { title: 'DOB', field: 'dateOfBirth', render: (rowData: any) => <>{(rowData.dateOfBirth)?format(new Date(rowData.dateOfBirth), 'dd/MM/yyyy'):'-'}</>  },
        { 
            title: 'Transfer Date',
            field: 'transferDate',
            render: (rowData: any) => {
                return rowData.transferDate !== null ? <> {format(new Date(rowData.transferDate), 'dd/MM/yyyy')}</> : <>-</>
                }
            
        }
    ];

    useEffect(() => {
        fetchData();
        fetchHorseGraphData();
    }, [userId]);

    const classes = useStyles();
    const chartClasses = useChartStyles();

    function goToHorse(horseId: string) {
        history.push("/Horse/" + horseId);
    }

    return (
        <> 
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card chart>
                        <CardHeader color="warning">
                            <ChartistGraph
                                className="ct-chart"
                                data={userHorsePointsPerUpload}
                                type="Bar"
                                options={userHorsePointsPerUploadOptions}
                            />
                        </CardHeader>
                        <CardBody>
                            <h4 className={chartClasses.cardTitle}>Horses</h4>
                        </CardBody>
                        <CardFooter chart >
                            <div className={chartClasses.stats}>
                            </div>
                        </CardFooter>
                        <MaterialTable
                            columns={columns}
                            data={data.items}
                            onRowClick={(e: any, data: any) => goToHorse(data.horseId)}
                            isLoading={isLoading}
                            components={{
                                Header: d =>
                                    <TableHead>
                                        <TableRow>
                                            {columns && columns.map(h =>
                                                <TableCell key={h.title}>
                                                    {h.title}
                                                </TableCell>
                                            )}
                                        </TableRow>

                                    </TableHead>
                            }}

                            options={{
                                search: false,
                                toolbar: false,
                                paging: false,
                                showEmptyDataSourceMessage: true,
                                sorting: false,
                                showTitle: false,
                                loadingType: 'linear',
                                debounceInterval: 200,
                                rowStyle: (rowData) => {
                                    if(rowData.isActive === false) {
                                        return {
                                        color: "grey"
                                        }
                                    } else {
                                        return {};
                                    }
                                  },
                            }}


                        />
                    </Card> 
                </Grid>              
            </Grid>
        </>
    );
}

