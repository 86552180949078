import { Button, Icon, makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import { toastr } from '../_components/toastr';
import { history } from '../_helpers/history';
import { accountService } from '../_services';
import { horsesService } from '../_services/horse.service';
import { dropdownService } from '../_services/dropdown.service';

interface Horse {
    name: string;
    age: number;
    dateOfBirth: Date;
    categoryId: number;
    genderId: number;
    isActive: boolean;
    [key: string]: any;
}

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-4),
        marginRight: theme.spacing(-1),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    closeButton: {
        float: "right",
        paddingTopt: theme.spacing(1)
    },
    drawer: {
        padding: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    gridItem: {
        padding: "0px !important"
    },
    select: {
        marginTop: "16px",
        marginBottom: "8px"
    }
}));

type PropsFunction = () => void;

export function HorseManage({ successCallback }: { successCallback: () => void }) {

    const [isLoading, setLoading] = useState(false);
    const [horse, setUpdateHorse] = useState<Horse>({ name: '', age: 0, categoryId: 0, genderId: 0, dateOfBirth: new Date(), code: '', id: 0, isActive: true });
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [dams, setDams] = useState([]);
    const [sires, setSires] = useState([]);
    const [genders, setGenders] = useState([]);
    const [categories, setHorseCategories] = useState([]);

    const classes = useStyles();
    const { horseId } = useParams<{ horseId: string }>();

    function navigateBack() {
        history.push('/Horses');
    };


    useEffect(() => {
        fetchData();
    }, [horseId]);

    useEffect(() => {
        fetchDams();
        fetchSires();
        fetchGenders();
        fetchHorseCategories();
    }, []);

    function fetchDams() {
        return dropdownService.getDams()
            .then(data => {
                setDams(data);
            });
    }

    function fetchSires() {
        return dropdownService.getSires()
            .then(data => {
                setSires(data);
            });
    }

    function fetchGenders() {
        return dropdownService.getGenders()
            .then(data => {
                setGenders(data);
            });
    }

    function fetchHorseCategories() {
        return dropdownService.getHorseCategories()
            .then(data => {
                setHorseCategories(data);
            });
    }

    function fetchData() {
        return horsesService.get(horseId)
            .then(data => {
                var _date = new Date(data.dateOfBirth);

                var month = _date.getMonth() < 9 ? "0" + (_date.getMonth() + 1) : (_date.getMonth() + 1);
                var date = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate();

                data.dateOfBirth = _date.getFullYear() + "-" + month + "-" + date;
                setUpdateHorse(data);
            });
    }

    function submit(e: any) {
        e.preventDefault();
        setLoading(true);

        horsesService
            .put(horseId, horse)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function disable(e: any) {
        e.preventDefault();
        setLoading(true);
        horsesService
            .disable(horseId)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function handleChange(event: any) {
        var _horse = horse;
        _horse[event.target.name] = event.target.value;
        setUpdateHorse({ ..._horse });
    };

    function handleDateChange(event: any) {
        var _horse = horse;
        _horse.dateOfBirth = event.target.value;
        setUpdateHorse({ ..._horse });
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">

                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">

                        <div className={classes.heading}>
                            <h4>
                                Update Horse

                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>

                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate>
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Horse Name"
                                name="horseName"
                                value={horse.horseName}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                type="number"
                                required
                                fullWidth
                                label="Age"
                                name="age"
                                value={horse.age}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                type="date"
                                fullWidth
                                label="Date of Birth"
                                name="DateOfBirth"
                                value={horse.dateOfBirth}
                                disabled={isLoading}
                                onChange={handleDateChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Code"
                                name="code"
                                value={horse.code}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />

                            <SelectValidator
                                id="gender"
                                value={horse.genderId}
                                onChange={handleChange}
                                className={classes.select}
                                required
                                label="Select Gender"
                                name="genderId"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined">
                                {(genders && genders.length > 0) && genders.map((item: any) =>
                                    <MenuItem key={`gen_${item.id}`} value={item.id}>{item.name}</MenuItem>
                                )}
                            </SelectValidator>

                            <SelectValidator
                                id="category"
                                value={horse.categoryId}
                                onChange={handleChange}
                                className={classes.select}
                                required
                                label="Select Category"
                                name="categoryId"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined">
                                {(categories && categories.length > 0) && categories.map((item: any) =>
                                    <MenuItem key={`cat_${item.id}`} value={item.id}>{item.name}</MenuItem>
                                )}
                            </SelectValidator>

                            <Grid container spacing={0}>
                                <Grid container item spacing={0} xs={horse.isActive === false ? 12 : 6}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isLoading}
                                        disableElevation
                                        className={classes.submit}
                                    >
                                        <Icon>check</Icon> {horse.isActive === false ? <>Save and Reactivate</> : <>Save</>}
                                    </Button>
                                </Grid>
                                {(horse.isActive === true) &&
                                    <Grid container item spacing={0} xs={6}>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disabled={isLoading}
                                            disableElevation
                                            className={classes.submit}
                                            onClick={(e:any) => setIsDialogOpen(true)}
                                        >
                                            <Icon>remove</Icon> Disable
                                    </Button>
                                    </Grid>
                                }
                            </Grid>
                        </ValidatorForm>
                    </div>
                </div>

            </Drawer>


            <div>
                <Dialog
                    open={isDialogOpen}
                    onClose={(e) => setIsDialogOpen(false)}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Confirmation
                        </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <strong>Are you sure you want to disable <i>"{horse.horseName}"</i>?</strong>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={(e) => setIsDialogOpen(false)} color="secondary">
                            Cancel
                            </Button>

                        <Button disabled={isLoading} variant="contained" type="button" onClick={disable} color="primary">
                            Confirm
                            </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    );
}