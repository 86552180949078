import { Button, makeStyles, Icon } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Component, FormEvent, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import DateFnsUtils from '@date-io/date-fns';
import { history } from '../_helpers/history';
import { competitionService } from '../_services/competition.service';
import { toastr } from '../_components/toastr';
import { accountService } from '../_services';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(-4),
        marginRight: theme.spacing(-1),
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(1)
    },
    closeButton: {
        float: "right",
        paddingTopt: theme.spacing(1)
    },
    appScreen: {
        backgroundColor: "#181c37",
        backgroundImage: "url('images/dark-tire.png')",
    },

    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    appContainer: {
        paddingTop: theme.spacing(4),
    },
    drawer: {
        padding: theme.spacing(1)
    },
}));

export function AccountCreate({ successCallback }) {

    const [isLoading, setLoading] = useState(false);
    const [receiveNotificationEmails, setReceiveNotificationEmails] = useState(true);
    const [account, updateAccount] = useState({ name: '', email: '', password: '', role: '', department: ''});
    const classes = useStyles();

    function goTo(link) {
        history.push('/' + link);
    }

    function navigateBack() {
        history.push('/Accounts');
    };

    function submit(e) {
        e.preventDefault();
        setLoading(true);

        var _account = account;
        _account.roles = [account.role];
        _account.receiveNotificationEmails = receiveNotificationEmails;

        accountService
            .post(_account)
            .then(
                (d) => { console.log(d); successCallback(); navigateBack(); },
                (e) => { console.log(e); setLoading(false); toastr.error("Something went wrong. Please try again later") }
            );
    }

    function handleChange(event) {
        var _account = account;
        _account[event.target.name] = event.target.value;
        updateAccount({ ..._account });
    };

    function handleReceiveNotificationsChange() {
        setReceiveNotificationEmails(prev => !prev);
    }

    return (
        <>
            <Drawer anchor="right" open={true} onClose={navigateBack} variant="temporary">

                <div
                    tabIndex={0}
                    className={classes.drawer}
                    role="button">
                    <div className="padding-sm">

                        <div className={classes.heading}>
                            <h4>
                                Create Account

                                <Icon className={classes.closeButton} onClick={navigateBack}>
                                    close
                                </Icon>
                            </h4>
                        </div>

                        <ValidatorForm className={classes.form} onSubmit={submit} noValidate autocomplete="off">
                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="User Name"
                                name="name"
                                value={account.name}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Department"
                                name="department"
                                value={account.department}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                autoFocus
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                value={account.email}
                                disabled={isLoading}
                                onChange={handleChange}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Must be in email format']}
                            />

                            <TextValidator
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Initial Password"
                                name="password"
                                value={account.password}
                                disabled={isLoading}
                                onChange={handleChange}
                                type="password"
                                validators={['required', 'minStringLength:8', 'maxStringLength:100',]}
                                errorMessages={['This field is required', 'Password must be at least 8 characters long', 'Password must be no more than 100 characters long']}
                            />


                            <SelectValidator labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={account.role}
                                onChange={handleChange}
                                required
                                label="Select Role"
                                name="role"
                                fullWidth
                                validators={['required']}
                                errorMessages={['This field is required']}
                                variant="outlined">
                                <MenuItem value='Admin'>Admin</MenuItem>
                                <MenuItem value='User'>User</MenuItem>
                            </SelectValidator>
                            <FormControlLabel
                                control={<Switch checked={receiveNotificationEmails} onChange={handleReceiveNotificationsChange} />}
                                label="Receive notification emails?"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={isLoading}
                                disableElevation
                                className={classes.submit}
                            >
                                <Icon>check</Icon> Save
                            </Button>

                        </ValidatorForm>
                    </div>
                </div>
            </Drawer>
        </>
    );
}