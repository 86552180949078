import { authHeader } from './auth-header';

export default function getHeader() {

  let header = authHeader();
  header["Content-Type"] = 'application/json';

  return header;
}

function getUploadHeader() {
  let header = authHeader();
  header['content-type'] = 'multipart/form-data';
  return header;
}

export { getHeader, getUploadHeader }