import { API_URL } from '../_config/const';
import { getHeader, getQuery } from '../_helpers';
import { handleResponse } from '../_helpers/handle-response';
import { getBody } from '../_helpers/body-helper';

const API_NAME = "competitionUser";

export const competitionUserService = {
    post,
    any,
    getAll,
    approve,
    getRules,
    transferHorse,
    getTransferTokens
};

function post(model: any) {
    const requestOptions = { method: 'POST', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/`, requestOptions).then(handleResponse);
}

function any() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/any`, requestOptions).then(handleResponse);
}

function getRules() {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/rules`, requestOptions).then(handleResponse);
}

function getAll(query: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/?${getQuery(query)}`, requestOptions).then(handleResponse);
}

function approve(id: any) {
    const requestOptions = { method: 'PUT', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/approve/?id=` + id, requestOptions).then(handleResponse);
}

function transferHorse(model: any) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/TransferHorse`, requestOptions).then(handleResponse);
}

function getTransferTokens(id: any) {
    const requestOptions = { method: 'GET', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/GetTransferTokens/?userId=` + id, requestOptions).then(handleResponse);
}
/*
function put(id, model) {
    const requestOptions = { method: 'PUT', headers: getHeader(), body: getBody(model) };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}

function remove(id) {
    const requestOptions = { method: 'DELETE', headers: getHeader() };
    return fetch(`${API_URL}/${API_NAME}/${id}`, requestOptions).then(handleResponse);
}
*/