import { BottomNavigation, BottomNavigationAction, Box, Container, createMuiTheme, Hidden, Icon, Menu, MenuItem } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import 'typeface-roboto';
import { AccountIndex } from '../account/account-index';
import { AuthRoute } from '../authRoute/authRoute';
import AppSidebar from '../common/AppSidebar';
import { AppToolbar } from '../common/AppToolbar';
import { CompetitionIndex } from '../competition/competition-index';
import { CompetitionJoin } from '../competition/competition-join';
import { CompetitionTransfer } from '../competition/competition-transfer';
import { CompetitionUsers } from '../competition/competition-users';
import { DashboardIndex } from '../dashboard/dashboard-index';
import { HorsesIndex } from '../horses/horses-index';
import { HorseView } from '../horses/horses-view';
import { RulesIndex } from '../rules/rules-index';
import { StandingsIndex } from '../standings/standings-index';
import { UploadHorsesView } from '../uploads/uploads-horsesView';
import { UploadsIndex } from '../uploads/uploads-index';
import { UploadRaceResultsView } from '../uploads/uploads-raceResultsView';
import { UserView } from '../user/user-view';
import { ADMIN_NAV, PRIMARY_NAV } from '../_consts/navigationConsts';
import { history } from '../_helpers/history';
import horse_icon_black from '../_images/horse_icon_black.png';
import { requestForToken } from '../_messaging/firebase/firebase-sw';
import { accountService } from '../_services';
import './dashboard.css';
import { NotificationIndex } from '../notifications/notification-index'
import { ResultsIndex } from '../results/results-index';
import { NotificationBanner } from '../notifications/notification-banner';


const useStyles = makeStyles(theme => ({

    appScreen: {
        display: 'flex',
    },
    main: {
        marginTop: "40px"
    },
    root: {

    },
    coolmoreLogo: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    mainBackground: {},
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid #eee'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(5),
        maxWidth: "100%"
    },
    horseIcon: {
        width: "24px",
        opacity: "0.64"
    }
}));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#282f5b'
        },
    }
});

function goTo(link: string) {
    history.push('/' + link);
}

export function AppScreen() {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any, nav: any) => {
        setAnchorEl(null);

        if (nav && nav.url)
            goTo(nav.url);
    };

    return (
        <div className={classes.appScreen} id="app-screen">
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <AppToolbar></AppToolbar>

                <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
                    <AppSidebar></AppSidebar>
                </Box>

                <main className={classes.content}>
                    <div className={classes.toolbar} />

                    <NotificationBanner />

                    <AuthRoute exact path="/" component={DashboardIndex} />

                    <AuthRoute exact path="/Horse/:horseId" component={HorseView} />

                    <AuthRoute path="/Horses" component={HorsesIndex} />
                    <AuthRoute path="/Results" component={ResultsIndex} />
                    <AuthRoute exact path="/Dashboard" component={DashboardIndex} />
                    <AuthRoute exact path="/User/:userId" component={UserView} />
                    <AuthRoute exact path="/Rules" component={RulesIndex} />
                    <AuthRoute exact path="/Rules/Rules" component={RulesIndex} />
                    <AuthRoute exact path="/Standings" component={StandingsIndex} />

                    <AuthRoute path="/Uploads" component={UploadsIndex} />

                    <AuthRoute exact path="/Upload/Horses/:uploadId" component={UploadHorsesView} />
                    <AuthRoute exact path="/Upload/RaceResults/:uploadId" component={UploadRaceResultsView} />

                    <AuthRoute path="/Accounts" component={AccountIndex} />
                    <AuthRoute path="/Competitions" component={CompetitionIndex} />

                    <AuthRoute exact path="/Competition/:competitionId/Users" component={CompetitionUsers} />
                    <AuthRoute exact path="/Competition/:competitionId/Join" component={CompetitionJoin} />
                    <AuthRoute exact path="/Competition/Transfer/:userId" component={CompetitionTransfer} />

                    <AuthRoute path="/Notifications" component={NotificationIndex} />
                </main>

                <Hidden mdUp>
                    <BottomNavigation
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        showLabels
                        className={classes.stickToBottom}
                    >

                        {PRIMARY_NAV && PRIMARY_NAV.map(nav => {
                            return accountService.isAdmin && nav.name === 'Rules' ? null :
                                <BottomNavigationAction onClick={() => goTo(nav.url)} label={nav.mobileName || nav.name} icon={
                                    <>
                                        {nav.png == null &&
                                            <Icon>{nav.icon}</Icon>
                                        }

                                        {nav.png != null &&
                                            <img className={classes.horseIcon} src={horse_icon_black}></img>
                                        }
                                    </>

                                } /> 
                                }
                        )}

                        {accountService.isAdmin &&
                            <BottomNavigationAction label={'Admin'} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} icon={
                                <>
                                    <Icon>more_vert</Icon>
                                </>
                            } />
                        }

                        {accountService.isAdmin &&

                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >

                                {ADMIN_NAV && ADMIN_NAV.map(nav =>
                                    <MenuItem onClick={(e: any) => handleClose(e, nav)}>{nav.name}</MenuItem>
                                )}
                            </Menu>
                        }
                    </BottomNavigation>
                </Hidden>

            </ThemeProvider>
        </div >
    );
}
