export function getBody(model: object): string {

  var propNames = [];
  for (var property in model) {

    if (model.hasOwnProperty(property)) {

      propNames.push(property);
    }
  }

  return JSON.stringify(model, propNames);
}

// be careful using it.
export function getBodyWithArray(model: object): string {
  return JSON.stringify(model);
}